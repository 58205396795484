.sec1 {
	padding-top: 145px;
	background: #000 url(../img/sec1.png) 50% 0 no-repeat;
	padding-bottom: 115px;
	position: relative;
	overflow: hidden;
	// height: 1073px;
	video.video {
		opacity: 1;
	}
	.date {
		color: #ffffff;
		font-size: 24px;
		margin-bottom: 27px;
		span {
			color: #8848f7;
			text-transform: uppercase;
			font-weight: 700;
		}
	}
	.title {
		color: #ffffff;
		font-size: 94px;
		font-weight: 700;
		line-height: 90px;
		letter-spacing: -1.88px;
		max-width: 820px;
		margin-bottom: 31px;
		span {
			color: #8848f7;
			line-height: 92px;
			letter-spacing: -2.82px;
		}
	}
	.changing_title {
		transition: .2s linear;
		transform: translateY(0);
		opacity: 1;
		&.hidden {
			transform: translateY(-20px);
			opacity: 0;
		}
	}
	.desc {
		color: #ffffff;
		font-size: 20px;
		line-height: 28px;
		// max-width: 485px;
		margin-left: 257px;
		margin-top: -115px;
		margin-bottom: 80px;
		span{
			color: #b286ff;
			font-weight: 700;
			display: inline-block;
			background-color: #0e0e0e;
			padding: 0 5px;
		}
	}
	.presale-title {
		color: #ffffff;
		font-size: 22px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.44px;
		margin-bottom: 30px;
	}
	.btn {
		margin-right: 28px;
	}
	.btn-video {
		margin-right: 0;
		border: 2px solid #ffffff;
		background-color: transparent;
		background-image: url(../img/btn-play-icon.svg);
		background-repeat: no-repeat;
		background-position: calc(50% + 130px) 50%;
	}
}

.sec2 {
	padding: 20px 0;
	.about-tilte {
		color: #808080;
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
	}
	.about-logos {
		max-width: 853px;
		width: 100%;
		height: 92px;
		background: url(../img/about-logos.png) 50% no-repeat;
	}
}

.sec3 {
	background-color: #f6f6f6;
	padding-top: 90px;
	padding-bottom: 70px;
	.item {

		width: 260px;
		margin-bottom: 70px;
		.text {
			font-size: 18px;
			font-weight: 700;
			line-height: 28px;
			color: #0d0d0d;
		}
		.number {
			color: #8848f7;
			font-size: 68px;
			line-height: 50px;
			font-weight: 800;
			position: relative;
			margin-bottom: 20px;
			&:after {
				content: attr(data-inf);
				color: #020202;
				font-size: 24px;
				display: inline-block;
				vertical-align: top;
			}
		}
	}
	.more-items {
		display: block;
		text-align: center;
		width: 130px;
		opacity: 0.8;
		color: #1e1e1e;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		text-decoration: underline;
		margin: 0 auto;
	}
	.items-hidden {
		display: none;
		.item {
			&:nth-last-child(1),
			&:nth-last-child(2),
			&:nth-last-child(3) {
				margin-bottom: 0;
			}
		}
	}
}

.sec4 {
	background: url(../img/sec4.jpg) 50% 0 no-repeat;
	padding-top: 100px;
	position: relative;
	overflow: hidden;
	.sec-title {
		color: #000000;
		font-size: 68px;
		font-weight: 700;
		line-height: 70px;
		letter-spacing: -1.36px;
		max-width: 560px;
		margin-bottom: 74px;
		span {
			color: #8848f7;
		}
	}
	.text {
		color: #000000;
		font-size: 16px;
		line-height: 24px;
		max-width: 425px;
		b {
			font-weight: 700;
		}
	}
	.text2 {
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		line-height: 22px;
		max-width: 400px;
		box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		padding: 60px 49px;
		// background: #000000 url(../img/s4-t2-bg.png) 0 0 no-repeat;
		margin-left: -11px;
		overflow: hidden;
		position: relative;
		background-color: #000;
	}
	.text3 {
		width: 100%;
		max-width: 575px;
		position: absolute;
		color: #ffffff;
		font-size: 16px;
		bottom: 0;
		transform: translateX(389px);
		padding-left: 32px;
		padding-bottom: 45px;
		padding-top: 50px;
		background: url(../img/s4-t3-bg.png) 50% no-repeat;
		background-size: cover;
		b {
			display: block;
			color: #ffffff;
			font-size: 48px;
			font-weight: 800;
			line-height: 40px;
			span {
				font-size: 31px;
			}
		}
	}
}

.sec5 {
	background: url(../img/sec5.jpg) 50% 0 no-repeat;
	padding-bottom: 132px;
	.top-text {
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		line-height: 22px;
		max-width: 620px;
		padding-top: 45px;
		padding-bottom: 45px;
		padding-left: 75px;
		padding-right: 125px;
		box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		background-color: #000;
		background-position: 100% 0;
		background-repeat: no-repeat;
		// background-image: url(../img/s5-tt-bg.png);
		position: relative;
		overflow: hidden;
	}
	.block-title {
		color: #ffffff;
		font-size: 48px;
		font-weight: 700;
		line-height: 50px;
		margin-bottom: 80px;
	}
	.slider-trends {
		box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		background-color: #ffffff;
		height: 505px;
		max-width: 487px;
		margin: 0 auto;
		padding-top: 57px;
		padding-left: 70px;
		padding-right: 25px;
		.slick-dots {
			bottom: 42px;
		}
		.slick-prev {
			top: auto;
			bottom: 40px;
			left: 60px;
			z-index: 10;
		}
		.slick-next {
			top: auto;
			bottom: 40px;
			right: 60px;
			z-index: 10;
		}
	}
	.trend {
		color: #000000;
		font-size: 35px;
		font-weight: 500;
		line-height: 48px;
		letter-spacing: 0.7px;
		padding-top: 120px;
		background-position: 0 0;
		background-repeat: no-repeat;
		span {
			color: #8848f7;
		}
	}
	.trend1 {background-image: url(../img/trend1-icon.png);}
	.trend2 {background-image: url(../img/trend2-icon.png);}
	.trend3 {background-image: url(../img/trend3-icon.png);}
	.trend4 {background-image: url(../img/trend4-icon.png);}
	.trend5 {background-image: url(../img/trend5-icon.png);}
	.trend6 {background-image: url(../img/trend6-icon.png);}
	.block-desc {
		color: #ffffff;
		font-size: 16px;
		line-height: 24px;
		max-width: 400px;
		margin-bottom: 70px;
	}
}

.sec6 {
	padding: 120px 0;
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 70px;
		font-weight: 700;
		letter-spacing: -1.36px;
		margin-bottom: 45px;
	}
	.video {
		position: relative;
		height: 552px;
		background-color: #000;
		.play {
			background-image: url(../img/play.svg);
			background-position: 50%;
			background-repeat: no-repeat;
			position: absolute;
			top: calc(50% - 51px);
			left: calc(50% - 51px);
			width: 102px;
			height: 102px;
			border-radius: 50%;
			display: block;
		}
		.author-name {
			position: absolute;
			color: #ffffff;
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
			bottom: 0px;
			left: 35px;
			min-height: 40px;
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	.video-sm {
		height:269px;
		margin-bottom: 13px;
		width: 300px;
		.play {
			width: 70px;
			height: 70px;
			background-image: url(../img/play-sm.svg);
			top: calc(50% - 35px);
			left: calc(50% - 35px);
		}
		.author-name {
			bottom: 5px;
			left: 15px;
			min-height: 40px;
		}
	}
	.slider-vedeoreviews {
		.slick-dots {
			bottom: -52px;
		}
		.slick-prev {
			top: auto;
			left: calc(50% - 150px);
			bottom: -55px;
		}
		.slick-next {
			top: auto;
			right: calc(50% - 150px);
			bottom: -55px;
		}
	}
	.video {
		background-position: 50% 0;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		&:before {
			content: '';
			display: block;
			width: 100%;
			position: absolute;
			height: 110px;
			background: url(../img/speaker-layer.png) 50% 100% no-repeat;
			background-repeat: repeat-x;
			left: 0;
			bottom: -1px;
		}
		&.vr1 {background-image: url(../img/reviews/vr1.jpg);}
		&.vr2 {background-image: url(../img/reviews/vr2.jpg);}
		&.vr3 {background-image: url(../img/reviews/vr3.jpg);}
		&.vr4 {background-image: url(../img/reviews/vr4.jpg);}
		&.vr5 {background-image: url(../img/reviews/vr5.jpg);}
		&.vr6 {background-image: url(../img/reviews/vr6.jpg);}
		&.vr7 {background-image: url(../img/reviews/vr7.jpg);}
		&.vr8 {background-image: url(../img/reviews/vr8.jpg);}
		&.vr9 {background-image: url(../img/reviews/vr9.jpg);}
		&.vr10 {background-image: url(../img/reviews/vr10.jpg);}
	}
}

.sec7 {
	background-color: #f6f6f6;
	padding-top: 90px;
	overflow: hidden;
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 78px;
		font-weight: 700;
		letter-spacing: -1.36px;
		margin-bottom: 53px;
	}
	.part {
		width: 50%;
	}
	.block-white {
		background-color: #ffffff;
		padding-top: 60px;
		padding-bottom: 50px;
		padding-left: 60px;
		padding-right: 75px;
		.text {
			color: #0d0d0d;
			font-size: 16px;
			line-height: 22px;
			b {
				font-weight: 700;
			}
		}
	}
	.block-black {
		background-color: #171717;
		padding-top: 55px;
		padding-bottom: 55px;
		padding-left: 60px;
		padding-right: 70px;
		position: relative;
		overflow: hidden;
		.wave-wrap {
			position: absolute;
			bottom: 0;
			width: 100%;
			left: 0;
			height: 150px;
			overflow: hidden;
		}
		.block-title {
			color: #ffffff;
			font-size: 24px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 25px;
		}
		ul {
			li {
				color: #ffffff;
				font-size: 16px;
				line-height: 22px;
				padding-left: 33px;
				margin-bottom: 20px;
				background: url(../img/teaser-open-icon.svg) 0 3px no-repeat;
				cursor: pointer;
				transition: all 0.2s linear;
				position: relative;
				&:after {
					content: '';
					display: block;
					width: 200px;
					height: 100%;
					background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(#171717, 1) 100%);
					position: absolute;
					right: 0;
					top: 0;
				}
				span {
					display: none;
				}
				&.active {
					transition: all 0.2s linear;
					background: url(../img/teaser-close-icon.svg) 0 3px no-repeat;
					&:after {
						display: none;
					}
				}
			}
		}
		.text {
			color: #ffffff;
			font-size: 16px;
			line-height: 22px;
		}
	}
	.part2 {
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 2000px;
			background: url(../img/speakers-bg.png) 0 0 no-repeat;
			background-size: cover;
			transition: all 0.2s linear;
		}
	}
	.lob18-speakers-block {
		text-align: center;
		width: 100%;
		max-width: 505px;
		height: 563px;
		background: url(../img/lob18-spekers-bg.png) 50% no-repeat;
		background-size: cover;
		position: relative;
		display: inline-block;
		padding-top: 100px;
		img {
			margin-bottom: 60px;
		}
		.block-text {
			display: block;
			color: #ffffff;
			font-size: 35px;
			font-weight: 500;
			line-height: 48px;
			letter-spacing: 0.7px;
			&:after {
				content: '';
				display: block;
				width: 55px;
				height: 56px;
				border-radius: 50%;
				background: url(../img/arr-white.svg) 50% no-repeat;
				margin: 0 auto;
				margin-top: 60px;
			}
			span {
				display: block;
				text-decoration: underline;
			}
		}
	}
}

.sec8 {
	background-color: #f6f6f6;
	padding: 100px 0;
	.sec-title {
		color: #111111;
		font-size: 48px;
		line-height: 57px;
		font-weight: 700;
		letter-spacing: -0.96px;
		max-width: 880px;
		margin-bottom: 55px;
	}
	.slider-reviews {
		max-width: 960px;
		margin: 0 auto;
		.slick-dots {
			bottom: -72px;
		}
		.slick-prev {
			// top: auto;
			left: calc(50% - 550px);
			// bottom: -75px;
			width: 40px;
			height: 40px;
			&:before {
				font-size: 40px;
				background-size: 100%;
			}
		}
		.slick-next {
			// top: auto;
			right: calc(50% - 550px);
			// bottom: -75px;
			width: 40px;
			height: 40px;
			&:before {
				font-size: 40px;
				background-size: 100%;
			}
		}
	}
}

.sec9 {
	overflow: hidden;
	.part {
		width: 50%;
	}
	.part1 {
		padding: 100px 0;
	}
	.sec-title {
		color: #060606;
		font-size: 68px;
		line-height: 75px;
		font-weight: 700;
		letter-spacing: -1.36px;
		margin-bottom: 50px;
	}
	.text {
		color: #060606;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		max-width: 460px;
		margin-bottom: 60px;
	}
	.bottom-text {
		color: #050505;
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		max-width: 380px;
	}
	.part2 {
		position: relative;
		padding-top: 140px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 2000px;
			background: url(../img/schedule-bg.png) 0 0 no-repeat;
			background-size: cover;
		}
	}
	.lob18-schedule-block {
		width: 100%;
		max-width: 505px;
		height: 505px;
		background: url(../img/lob18-spekers-bg.png) 50% no-repeat;
		position: relative;
		padding-left: 100px;
		padding-top: 65px;
		.block-title {
			color: #ffffff;
			font-size: 35px;
			font-weight: 500;
			line-height: 48px;
			letter-spacing: 0.7px;
			margin-bottom: 30px;
		}
		.days-images {
			height: 130px;
			background: url(../img/days-images.png) 0 0 no-repeat;
			margin-bottom: 55px;
		}
		.schedule18-link {
			color: #ffffff;
			font-size: 16px;
			font-weight: 700;
			line-height: 22px;
			text-decoration: underline;
			position: relative;
			display: block;
			.arr {
				display: block;
				position: absolute;
				width: 57px;
				height: 57px;
				border-radius: 56px;
				background: url(../img/arr-white.svg) 50% no-repeat;
				right: 46px;
				top: 0;
			}
		}
	}
}

.sec10 {
	padding-top: 90px;
	padding-bottom: 80px;
	overflow: hidden;
	// background: url(../img/sec10bg.jpg) 50% 0 no-repeat;
	background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
	background-color: #000;
	position: relative;
	.wave-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 200px;
	}
	.vaucher-title {
		color: #ffffff;
		font-size: 48px;
		font-weight: 700;
		letter-spacing: -0.96px;
	}
	.open-sale-block {
		background:#892eca url(../img/open-sale-block-bg.jpg) 50% no-repeat;
		box-shadow: 0 0 90px rgba(0, 0, 0, 0.29);
		background-size: cover;
		width: 418px;
		padding-top: 60px;
		padding-bottom: 40px;
		padding-left: 135px;
		padding-right: 100px;
		position: relative;
		margin-bottom: 100px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -60px;
			left: 180px;
			background: url(../img/open-sale-block-lines.png) 0 0 no-repeat;
			width: 300px;
			height: 290px;
		}
		.open-sale-info {
			color: #ffffff;
			font-size: 24px;
			font-weight: 700;
			line-height: 30px;
		}
	}
}
.sec11 {
	background: #010a13 url(../img/sec11.jpg) 50% 0 no-repeat;
	padding-top: 200px;
	padding-bottom: 230px;
	overflow: hidden;
	.sec-title {
		display: inline-block;
		vertical-align: top;
		color: #ffffff;
		font-size: 48px;
		line-height:66px;
		font-weight: 700;
		letter-spacing: -0.96px;
		position: relative;
		margin-right: 290px;
		// span.line1 {
		// 	padding-top: absolute;
		// 	display: block;
		// 	width: 150px;
		// 	height: 1px;
		// 	background-color: #fff;
		// 	top: -300px;
		// 	right: 0;
		// 	transform: rotate(-45deg);
		// }
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 544px;
			height: 528px;
			background: url(../img/s11-lines.png) 0 0 no-repeat;
			top: -180px;
			left: 64px;
		}
		b {
			display: block;
			color: #ffffff;
			font-size: 86px;
			position: relative;
			z-index:1;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 104px 104px 0 0;
				border-color: #7053ff transparent transparent transparent;
				left: 159px;
				z-index: -1;
			}
		}
	}
	.lob18-link {
		margin-top: 38px;
		color: #ffffff;
		font-size: 22px;
		font-weight: 700;
		line-height: 36px;
		text-transform: uppercase;
		letter-spacing: 0.44px;
		max-width: 325px;
		display: inline-block;
		vertical-align: top;
		position: relative;
		span {
			display: block;
			width: 102px;
			height: 102px;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: -137px;
			border: 4px solid #ffffff;
			background: url(../img/play-triangle.svg) 38px 50% no-repeat;
		}
	}
}

.sec12 {
	background-color: #f6f6f6;
	padding-top: 100px;
	padding-bottom: 100px;
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 75px;
		font-weight: 700;
		letter-spacing: -1.36px;
		margin-bottom: 45px;
	}
	.text-block {
		background-color:rgba(#fff, .84);
		padding: 64px;
		.text1 {
			max-width: 528px;
			color: #0d0d0d;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 43px;
			b {
				font-weight: 700;
			}
		}
		.vaucher-title {
			color: #050505;
			font-size: 24px;
			font-weight: 700;
			line-height: 31px;
			margin-bottom: 25px;
		}
		.text2 {
			color: #000000;
			font-size: 16px;
			font-weight: 400;
			line-height: 23px;
			max-width: 440px;
			margin-bottom: 40px;
		}
	}
	.btn {
		max-width: 500px;
	}
	.slider-video {
		.videolink {
			width: 100%;
			height: 300px;
			background-position: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 16px;
			position: relative;
			.name {
				color: #ffffff;
				font-size: 15px;
				font-weight: 400;
				line-height: 28px;
				position: absolute;
				bottom: 20px;
				left: 20px;
			}
			.play {
				width: 87px;
				height: 87px;
				border-radius: 50%;
				border: 4px solid #ffffff;
				display: block;
				position: absolute;
				left: calc(50% - 43px);
				top: calc(50% - 43px);
				background: url(../img/play-triangle.svg) calc(50% + 3px) 50% no-repeat;
			}
		}
		.vl1 {background-image: url(../img/s12-vl1.png);}
		.vl2 {background-image: url(../img/s12-vl2.png);}
		.vl3 {background-image: url(../img/s12-vl3.jpg);}
		.vl4 {background-image: url(../img/s12-vl4.jpg);}
	}
	.slider-video {
		.slick-dots {
			bottom: -72px;
		}
		.slick-prev {
			top: auto;
			left: calc(50% - 150px);
			bottom: -75px;
		}
		.slick-next {
			top: auto;
			right: calc(50% - 150px);
			bottom: -75px;
		}
	}
}

.sec13 {
	padding-top: 90px;
	padding-bottom: 100px;
	.sec-title {
		color: #020202;
		font-size: 48px;
		line-height: 56px;
		font-weight: 700;
		letter-spacing: -0.96px;
		margin-bottom: 70px;
	}
	.company-logos {
		height: 400px;
		background: url(../img/compay-logos.png) 50% 0 no-repeat;
	}
	.slider {
		.partner {
			width: 150px;
			height: 150px;
			margin: 0 auto;
		}
	}
}

.sec14 {
	// background: url(../img/sec14.jpg) 50% 0 no-repeat;
	background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
	overflow: hidden;
	position: relative;
	.wave-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 200px;
	}
	.sec-title {
		color: #ffffff;
		font-size: 71px;
		font-weight: 700;
		line-height: 80px;
		letter-spacing: -1.43px;
		span {
			position: relative;
			display: inline-block;
			&:after {
				content: '';
				display: block;
				width: 32px;
				height: 32px;
				position: absolute;
				right: -45px;
				top: -5px;
				background: url(../img/star.svg) 50% no-repeat;
			}
		}
	}
	.photos1 {
		height: 453px;
		background: url(../img/photos1.png) 100% 0 no-repeat;
	}
	.white-block {
		box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		background-color: #ffffff;
		padding-bottom: 70px;
		padding-top: 70px;
		.text1 {
			padding-left: 58px;
			max-width: 470px;
			color: #000000;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 50px;
			b {
				font-weight: 700;
			}
		}
		.text2 {
			color: #050505;
			font-size: 24px;
			line-height: 30px;
			font-weight: 700;
			padding-left: 57px;
		}
		.text3 {
			color: #000000;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			max-width: 450px;
			margin-bottom: 65px;
		}
		.btn {
			max-width: 387px;
		}
	}
	.photos2 {
		height: 775px;
		background: url(../img/photos2.png) 100% 0 no-repeat;
		position: relative;
		.video {
			// display: block;
			position: absolute;
			top: 210px;
			right: 0;
			background: url(../img/photos2-video.jpg) 50% 0 no-repeat;
			background-size: cover;
			width: 300px;
			height: 205px;
			.play {
				width: 70px;
				height: 70px;
				background-image: url(../img/play-sm.svg);
				top: calc(50% - 35px);
				left: calc(50% - 35px);
				background-position: 50%;
				background-repeat: no-repeat;
				border-radius: 50%;
				display: block;
			}
		}
	}
	.party-title {
		color: #ffffff;
		font-size: 48px;
		line-height: 56px;
		font-weight: 700;
		max-width: 490px;
		margin-bottom: 62px;
	}
	.party-text {
		color: #ffffff;
		font-size: 16px;
		line-height: 22px;
		max-width: 475px;
		margin-bottom: 70px;
		b {
			font-weight: 700;
		}
	}
	.btn {
		span {
			font-size: 27px;
			display: inline-block;
			margin-left: 10px;
		}
	}
}

.sec15 {
	padding: 80px 0;
	.sec-title {
		color: #111111;
		font-size: 68px;
		font-weight: 700;
		line-height: 68px;
		letter-spacing: -1.36px;
		margin-bottom: 45px;
	}
	.item-wrap {
		height: 540px;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.iw1 {background-image: url(../img/iw1.jpg);}
	.iw2 {
		background-image: url(../img/iw2.jpg);
		.item {
			.item-text {
				max-width: 250px;
			}
		}
	}
	.iw3 {
		background-image: url(../img/iw3.jpg);
		.item {
			.item-text {
				max-width: 250px;
			}
		}
	}
	.item {
		width: 50%;
		height: 100%;
		background-image: linear-gradient(to left, #9a39e6 0%, #6735c4 100%);
		opacity: 0.97;
		.item-text {
			color: #ffffff;
			font-size: 16px;
			line-height: 22px;
			max-width: 300px;
			position: relative;
			&:before {
				content: attr(data-number);
				display: block;
				text-align: center;
				color: #ffffff;
				font-size: 86px;
				line-height: 80px;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 30px;
			}
		}
	}
}

.sec16 {
	position: relative;
	overflow: hidden;
	height: 1073px;
	padding-top: 90px;
	padding-top: 50px;
	padding-bottom: 130px;
	#inner-wrap {
  	position: absolute;
  	top: 0;
  	left: 0;
  	right: 0;
  	display: table;
  	width: 100%;
  	height: 400px;
  	min-height: 322px;
  	overflow: visible;
	}

	.waves {
  	position: absolute;
  	top: 0;
  	left: 0;
  	right: 0;
  	bottom: 0;
  	display: block;
  	min-width: 100%;
  	min-height: 70%;
  	margin: auto 0;
	}
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: -2;
		background-color: #000;
		top: 0;
		left: 0;
	}
	.sec-title {
		color: #ffffff;
		font-size: 94px;
		line-height: 105px;
		font-weight: 700;
		letter-spacing: -1.88px;
		margin-bottom: 100px;
		padding-left: 105px;

		span {
			position: relative;
		}
	}
	.white-block {
		background-color: #ffffff;
		padding: 70px 55px;
		max-width: 1114px;
		margin: 0 auto;
		margin-bottom: 85px;
		.text {
			color: #0e0e0e;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			max-width: 475px;
			b {
				font-weight: 700;
			}
		}
		.text-big{
			color: #050505;
			font-size: 24px;
			line-height: 30px;
			font-weight: 800;
			max-width: 320px;
			margin: 45px 0;
		}
		.text2 {
			max-width: 435px;
		}
	}
	.bottom-block {
		max-width: 1114px;
		margin: 0 auto;
		.block-title {
			color: #ffffff;
			font-size: 24px;
			line-height: 31px;
			font-weight: 700;
			max-width: 170px;
		}
		ul {
			li {
				color: #ffffff;
				font-size: 16px;
				font-weight: 700;
				line-height: 30px;
			}
		}
	}
	.btn {
		width: 500px;
		line-height: 83px;
	}
}


.sec17 {
	padding-top: 90px;
	padding-bottom: 145px;
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 75px;
		font-weight: 700;
		letter-spacing: -1.36px;
		max-width: 950px;
		margin-bottom: 60px;
	}
	.slider-gallery {
		.slick-dots {
			bottom: -72px;
		}
		.slick-prev {
			top: auto;
			left: calc(50% - 150px);
			bottom: -75px;
		}
		.slick-next {
			top: auto;
			right: calc(50% - 150px);
			bottom: -75px;
		}
	}
	.slide {
		height: 823px;
		// background: url(../img/gallery.png) 50% 0 no-repeat;
		position: relative;
	}
	.photo {
		position: absolute;
		background-size: cover;
		background-position: 50% 0;
		background-repeat: no-repeat;
	}
	.photo1,
	.photo9 {
		left: 0;
		top: 0;
		width: 405px;
		height: 265px;
		background-image: url(../img/gallery/1.jpg);
	}
	.photo2,
	.photo10 {
		left: 0;
		top: 280px;
		width: 405px;
		height: 265px;
		background-image: url(../img/gallery/2.jpg);
	}
	.photo3,
	.photo11 {
		left: 0;
		bottom:0;
		width: 310px;
		height: 265px;
		background-image: url(../img/gallery/3.jpg);
	}
	.photo4,
	.photo12 {
		left: 420px;
		top:0;
		width: 570px;
		height: 545px;
		background-image: url(../img/gallery/4.jpg);
	}
	.photo5,
	.photo13 {
		left: 324px;
		bottom:0;
		width: 500px;
		height: 265px;
		background-image: url(../img/gallery/5.jpg);
	}
	.photo6,
	.photo14 {
		right:0;
		top:0;
		width: 235px;
		height: 265px;
		background-image: url(../img/gallery/6.jpg);
	}
	.photo7,
	.photo15 {
		right:0;
		top:280px;
		width: 235px;
		height: 265px;
		background-image: url(../img/gallery/7.jpg);
	}
	.photo8,
	.photo16 {
		right:0;
		bottom:0;
		width: 405px;
		height: 265px;
		background-image: url(../img/gallery/8.jpg);
	}
	.photo9 {background-image: url(../img/gallery/9.jpg);}
	.photo10 {background-image: url(../img/gallery/10.jpg);}
	.photo11 {background-image: url(../img/gallery/11.jpg);}
	.photo12 {background-image: url(../img/gallery/12.jpg);}
	.photo13 {background-image: url(../img/gallery/13.jpg);}
	.photo14 {background-image: url(../img/gallery/14.jpg);}
	.photo15 {background-image: url(../img/gallery/15.jpg);}
	.photo16 {background-image: url(../img/gallery/16.jpg);}
}

.sec18 {
	padding-top: 180px;
	padding-bottom: 80px;
	position: relative;
	.bird {
		position: absolute;
		width: 350px;
		height: 390px;
		background: url(../img/bird.png) 50% no-repeat;
		top: 0;
		left: calc(50% - 300px);
	}
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 75px;
		font-weight: 700;
		span {
			display: block;
			position: relative;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 104px 104px 0 0;
				border-color: #a340f4 transparent transparent transparent;
				left: 166px;
				z-index: -1;
			}
		}
	}
	.text {
		color: #0d0d0d;
		font-size: 16px;
		line-height: 24px;
	}
}

.sec19 {
	background-image: linear-gradient(to right, #3b2281 0%, #3b2281 34%, #7d2fc7 56%, #9c30d9 67%, #9c30d9 100%);
	padding-top: 100px;
	padding-bottom: 80px;
	.slider-text {
		// background-color: #fff;
		margin-bottom: 100px;
		.slick-dots {
			bottom: 27px;
		}
		.slick-prev {
			top: auto;
			left: calc(50% - 150px);
			bottom: 25px;
		}
		.slick-next {
			top: auto;
			right: calc(50% - 150px);
			bottom: 25px;
		}
	}
	.white-block {
		// box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		background-color: #ffffff;
		padding: 70px 100px;
		min-height: 390px;
		// margin-bottom: 100px;
		.man-info {
			max-width: 210px;
			.man-photo {
				width: 166px;
				height: 166px;
				border-radius: 50%;
				margin-bottom: 20px;
				// background: url(../img/s19-man-photo.png) 50% no-repeat;
				background-position: 50% 0;
				background-size: cover;
				background-repeat: no-repeat;
				&.chapman {background-image: url(../img/speakers2018/chapman_new2.jpg);}
				&.karpyuk {background-image: url(../img/speakers2018/karpyuk_new.jpg);}
				&.solovev {background-image: url(../img/speakers2018/solovev_new.jpg);}
				&.lisenko {background-image: url(../img/speakers2018/lisenko.jpg);}
			}
			.man-name {
				padding-left: 21px;
				color: #000000;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}
			.man-prof {
				padding-left: 21px;
				color: #000000;
				font-size: 16px;
				line-height: 20px;
			}
		}
		.citate {
			color: #000000;
			font-size: 35px;
			font-weight: 500;
			line-height: 48px;
			letter-spacing: 0.7px;
			max-width: 710px;
			position: relative;

			&:before {
				content:'';
				display: block;
				position: absolute;
				width: 50px;
				height: 40px;
				background: url(../img/citate-icon.png) 0 0 no-repeat;
				left: 0;
				top: -50px;
			}
		}
	}
	.vaucher-title {
		color: #ffffff;
		font-size: 48px;
		font-weight: 700;
		letter-spacing: -0.96px;
		margin-right: 275px;
	}
	.open-sale-info {
		color: #ffffff;
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		max-width: 205px;
		padding-top: 26px;
		padding-left: 23px;
		background: url(../img/osi-decor.png) 0 0 no-repeat;
		margin-bottom: 130px;
	}
}

.sec20 {
	padding-top: 100px;
	padding-bottom: 50px;
	.sec-title {
		color: #111111;
		font-size: 68px;
		line-height: 75px;
		font-weight: 700;
		margin-bottom: 60px;
	}
	.map {
		height: 409px;
		position: relative;
		margin-bottom: 90px;
	}
	.place-info-block {
		position: absolute;
		top: 40px;
		right: 50px;
		width: 457px;
		height: 329px;
		background-color: rgba(#fff, 0.93);
		background-image: url(../img/map-bg.png);
		background-position: 100% 0;
		background-repeat: no-repeat;
		padding-left: 60px;
		padding-top: 45px;
		.plus-wrap {
			position: relative;
			width: 100%;
			.plus {
				position: absolute;
				width: 31px;
				height: 31px;
				right: 50px;
				top: 20px;
				background: url(../img/plus.svg) 50% no-repeat;

			}
		}
		.place-name {
			color: #0c0c0c;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 15px;
			b {
				display: block;
				color: #000000;
				line-height: 30px;
				font-size: 24px;
				font-weight: 800;
			}
		}
		p.place-adress {
			color: #000000;
			font-size: 16px;
			line-height: 21px;
			font-weight: 400;
			padding-left: 22px;
			background: url(../img/adress-icon.svg) 0 0 no-repeat;
			margin-bottom: 25px;
		}
		.text {
			color: #000000;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		.phone {
			display: block;
			color: #0c0c0c;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 10px;
		}
		.email {
			color: #0c0c0c;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			text-decoration: underline;
		}
	}
	.faq {
		.block-title {
			color: #111111;
			font-size: 48px;
			font-weight: 700;
			line-height: 55px;
			letter-spacing: -0.96px;
		}
		.question {
			padding-left: 45px;
			margin-bottom: 50px;
			position: relative;
			transition: all 0.3s linear;
			&:before {
				content: '';
				display: none;
				position: absolute;
				width: 85px;
				height: 85px;
				background: url(../img/question-icon.png) 50% no-repeat;
				left: -32px;
				top: -23px;
				transition: all 0.3s linear;
			}
			&.active {
				transition: all 0.3s linear;
				&:before {
					transition: all 0.3s linear;
					display: block;
				}
				.question-text {
					text-decoration: none;
				}

			}
		}
		.question-text {
			color: #0a0a0a;
			font-size: 24px;
			font-weight: 800;
			line-height: 31px;
			text-decoration: underline;
			cursor: pointer;
		}
		.answer {
			color: #000000;
			font-size: 16px;
			line-height: 22px;
			margin-top: 20px;
			display: none;
		}
	}
}

.sec21 {
	background-color: #f3f3f3;
	padding-top: 90px;
	padding-bottom: 60px;
	.sec-title {
		color: #111111;
		font-size: 68px;
		font-weight: 700;
		line-height: 75px;
		letter-spacing: -1.36px;
		margin-bottom: 65px;
	}
	.block-title {
		color: #020202;
		font-size: 24px;
		font-weight: 800;
		line-height: 30px;
		margin-bottom: 15px;
	}
	.sponsors-block {
		margin-bottom: 100px;
	}

	.sponsors-slider {
		.sponsor {
			display: inline-block;
			float: left;
			vertical-align: top;
			width: 100%;
			max-width: 440px;
			margin: 0 12px;
			height: 242px;
			// background-color: #FFF;
			background-repeat: no-repeat;
			background-position: 50%;
		}
		.sponsor1 {background-image: url(../img/sponsors/sponsor1.jpg);}
		.sponsor2 {background-image: url(../img/sponsors/sponsor2.png);}
	}
	.partners-slider {
		.partner {
			display: inline-block;
			vertical-align: top;
			width: 300px;
			height: 150px;
			margin: 0 auto;
			background-repeat: no-repeat;
			background-position: 50%;
			outline: none;
		}
	}
	.partner1 {background-image: url(../img/partners/partner1.png);}
	.partner2 {background-image: url(../img/partners/partner2.png);}
	.partner3 {background-image: url(../img/partners/partner3.png);}
	.partner4 {background-image: url(../img/partners/partner4.png);}
	.partner5 {background-image: url(../img/partners/partner5.jpg);}
	.partner6 {background-image: url(../img/partners/partner6.jpg);}
	.partner7 {background-image: url(../img/partners/partner7.png);}
	.partner8 {background-image: url(../img/partners/partner8.png);}
	.partner9 {background-image: url(../img/partners/partner9.png);}
	.partner10 {background-image: url(../img/partners/partner10.png);}
	.partner11 {background-image: url(../img/partners/partner11.png);}
	.partner12 {background-image: url(../img/partners/partner12.png);}


}

.sec22 {
	// background: url(../img/sec22.jpg) 50% 0 no-repeat;
	background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
	padding-top: 120px;
	padding-bottom: 85px;
	position: relative;
	overflow: hidden;
	.wave-wrap {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 200px;
	}
	.sec-title {
		color: #ffffff;
		line-height: 56px;
		font-size: 48px;
		font-weight: 700;
		letter-spacing: -0.96px;
		margin-bottom: 50px;
	}
	.info-block {
		max-width: 360px;
		margin: 0 auto;
		.text1 {
			color: #ffffff;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 70px;
		}
		.text2 {
			color: #fdfdfd;
			font-size: 23px;
			line-height: 30px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.46px;
			padding-left: 111px;
			background: url(../img/s22-t2-decor.svg) 0 0 no-repeat;
			margin-bottom: 125px;
			span {
				text-decoration: underline;
				a {color: #fff;}
			}
		}
	}
	.btn {
		max-width: 395px;
		padding-left: 30px;
		background-repeat: no-repeat;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: calc(50% - 100px);
			top: calc(50% - 15px);
			background-repeat: no-repeat;
			background-position: 50%;
			width: 30px;
			height: 30px;
		}
	}
	.btn-sponsor:before {
		background-image: url(../img/btn-sponsor-icon.svg);
	}
	.btn-partner:before {
		background-image: url(../img/btn-partner-icon.svg);
	}
	.btn-stend:before {
		background-image: url(../img/btn-stend-icon.png);
	}
}

.sec23 {
	background: #030303 url(../img/sec23.png) 50% 0 no-repeat;
	padding-top: 100px;
	padding-bottom: 75px;
	.sec-title {
		color: #ffffff;
		font-size: 48px;
		font-weight: 700;
		line-height: 50px;
		letter-spacing: -0.96px;
		max-width: 400px;
		margin-bottom: 120px;
	}
	.text1 {
		color: #ffffff;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		max-width: 320px;
		margin-bottom: 35px;
	}
	.text2 {
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		margin-bottom: 115px;
		span {
			color: #fd1a83;
		}
	}
	.white-block {
		box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
		background-color: #ffffff;
		padding-top: 80px;
		padding-bottom: 80px;
		padding-left: 413px;
		background: #fff url(../img/s23-vaucher.png) 0 0 no-repeat;
		.vaucher-title {
			color: #000000;
			font-size: 35px;
			font-weight: 500;
			line-height: 48px;
			letter-spacing: 0.7px;
			margin-bottom: 40px;
			span {
				color: #7153ff;
			}
		}
	}
}


