body {
	font-family: Geometria, Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4;
	min-width: 320px;
	color: #000000;
	position: relative;
	background-color: #ffffff;
}

////Удалить или закоментировать при адаптивном дизайне!//////////////
// .container {width: 960px !important;}
//////////////////////////////////////////////////////////////////////

video.video {
  min-height: 720px;
  left: 0;
  position: absolute;
  top: 0;
  min-width: 100%;
  background-color: #0f0f0f;
  opacity: 0.5;
  z-index: -1; }

canvas.canvas {
   min-height: 720px;
   left: 0;
   position: absolute;
   top: 0;
   min-width: 100%;
   /* background: #000; */
   z-index: -1;
}


#wave1,
#wave2,
#wave3,
#wave4,
#wave5,
#wave6 {
  // width: 100%;
  // height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}


#wave3 {
  // height: 100px;
  // position: relative;
  bottom: 0;
  top: auto;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}


.hidden {
	display: none;
}
.wrapper {
	margin: 0 auto;
}

a {
	text-decoration: none;
}

//validatorGM styles//
 form input.error {
 	box-shadow: inset 0px 0px 20px 1px rgba(255,0,0,0.3);
 	border: 2px solid red !important;}
 form input.not_error {
 	box-shadow: inset 0px 0px 20px 1px rgba(100,255,100,0.3);
 	border: 2px solid #99FF99 !important;}

.slick-prev,
.slick-next {
  z-index: 10;
}

.slick-next:before {
  background: url(../img/next.svg) 50% no-repeat;
  color: transparent;
}
.slick-prev:before {
  background: url(../img/prev.svg) 50% no-repeat;
  color: transparent;
}

.slick-dots {
  bottom: -40px;
  left: 0;
  li {
    button:before {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #000;
      color: transparent;
    }
    &.slick-active {
      button:before {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #fff;
        color: transparent;
        border: 2px solid #000000;
        margin-top: -3px;
      }
    }
  }
}



@keyframes pulsating {
  0% {
   transform: scale(1); }
  50% {
   transform: scale(1.1); }
  100% {
    transform: scale(1);} }

.pulsating {
  animation-name: pulsating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

