.modal-overlay,
.modal-white {
	display: none;
	padding-top: 1px;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	z-index: 1000000;
	overflow: auto;
	.modal-content {
		margin: 5vh auto;
		width: 60vw;
		padding:30px;
		// background:#fff;
		margin-bottom: 0px;
		border-radius: 4px;
		height:70vh;
	}
	.close-button {
		position: absolute;
		width: 134px;
		height: 70px;
		top: 0px;
		right: 30px;
		padding: 0px 14px;
		padding-bottom: 0;
		cursor: pointer;
		transition: 0.2s linear;
		background-color: #000;
		padding-top: 5px;
		.one {
			margin: 0 auto;
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transition: 0.2s linear;
		}
		.two {
			margin: 0 auto;
			margin-top: -50px;
			height: 50px;
			width: 2px;
			background-color: #fff;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transition: 0.2s linear;
		}
		&:hover {
			.one {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transition: 0.2s linear;
				background-color: #fff;
			}
			.two {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transition: 0.2s linear;
				background-color: #fff;
			}
		}
	}
}

#modal-video {
	.modal-content {
		height: auto;
		width: 100%;
	}
	.video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// #modal-speakers {
// 	display: flex;
// }

.modal-white {
	background-color: rgba(#f3f3f3, 0.98);
	background-image: url(../img/modal-white-bg.png);
	background-position: 50% 0;
	background-repeat: no-repeat;
	// display: flex;
	.schedule {
		padding-top: 70px;
		.schedule-header {
			margin-bottom: 60px;
		}
		.schedule-title {
			color: #111111;
			font-size: 22px;
			font-weight: 700;
			letter-spacing: -0.44px;
			text-transform: uppercase;
			span {
				color: #8848f7;
			}
		}
		.tabs {
			li {
				display: inline-block;
				&:last-child {
					a {
						border: none;
					}
				}
				a {
					padding: 0 40px;
					display: block;
					color: #0d0d0d;
					font-size: 16px;
					line-height: 22px;
					padding-bottom: 20px;
					border-right: 1px solid #bbbbbb;
					span {
						display: block;
						font-size: 22px;
						font-weight: 700;
						line-height: 36px;
						text-transform: uppercase;
						letter-spacing: 0.44px;
					}
				}
			}
		}
	}
	.day {
		.day-title {
			color: #111111;
			font-size: 68px;
			line-height: 75px;
			font-weight: 700;
			letter-spacing: -1.36px;
			position: relative;
			padding-left: 130px;
			margin-bottom: 50px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 100px;
				height: 2px;
				background-color: #111;
			}
		}
	}
	.day-item {
		margin-bottom: 2px;
		.item-number {
			background-color: #ffffff;
			height: 100%;
			padding-left: 17px;
			padding-top: 40px;
			color: #050505;
			font-size: 24px;
			font-weight: 700;
		}
		.item-content {
			background-color: #fff;
			height:100%;
			padding-top: 30px;
			padding-right: 60px;
			padding-left: 60px;
			padding-bottom: 50px;
			.speaker-info {
				max-width: 225px;
				width: 100%;
				.speaker-photo {
					width: 54px;
					height: 54px;
					border-radius: 50%;
					background-size: cover;
					background-position: 50% 0;
					background-repeat: no-repeat;
					margin-bottom: 10px;
					&.oles {background-image: url(../img/speakers2018/oles_new.jpg);}
					&.balakina {background-image: url(../img/speakers2018/balakina_new.jpg);}
					&.bovt {background-image: url(../img/speakers2018/bovt_new.jpg);}
					&.chapman {background-image: url(../img/speakers2018/chapman_new2.jpg);}
					&.drozd {background-image: url(../img/speakers2018/drozd_new.jpg);}
					&.fedoriv {background-image: url(../img/speakers2018/fedoriv_new.jpg);}
					&.fleisher {background-image: url(../img/speakers2018/fleisher_new.jpg);}
					&.ivanov {background-image: url(../img/speakers2018/ivanov_new.jpg);}
					&.karpyuk {background-image: url(../img/speakers2018/karpyuk_new.jpg);}
					&.kirigetov {background-image: url(../img/speakers2018/kirigetov_new.jpg);}
					&.kravec {background-image: url(../img/speakers2018/kravec_new.jpg);}
					&.krymenish {background-image: url(../img/speakers2018/krymenish.png);}
					&.kyzmin {background-image: url(../img/speakers2018/kuzmin_new.jpg);}
					&.lashkov {background-image: url(../img/speakers2018/lashkov_new.jpg);}
					&.lisenko {background-image: url(../img/speakers2018/lisenko.jpg);}
					&.logvin {background-image: url(../img/speakers2018/logvin_new.jpg);}
					&.mikula {background-image: url(../img/speakers2018/mikula_new.jpg);}
					&.noname {background-image: url(../img/speakers2018/noname_new.jpg);}
					&.plahtiy {background-image: url(../img/speakers2018/plahtiy_new.jpg);}
					&.postrigai {background-image: url(../img/speakers2018/postrigay_new.jpg);}
					&.riabikin {background-image: url(../img/speakers2018/riabikin_new.jpg);}
					&.secret {background-image: url(../img/speakers2018/secret_new.jpg);}
					&.solovev {background-image: url(../img/speakers2018/solovev_new.jpg);}
					&.zdor {background-image: url(../img/speakers2018/zdor_new.jpg);}
				}
				.speaker-name {
					color: #0d0d0d;
					font-size: 14px;
					font-weight: 500;
					line-height: 19px;
				}
			}
			.time {
				width: 120px;
				color: #0d0d0d;
				font-size: 16px;
				font-weight: 700;
				line-height: 24px;
			}
			.item-info {
				max-width: 450px;
				width: 100%;
				.item-title {
					color: #6622db;
					font-size: 16px;
					font-weight: 700;
					line-height: 24px;
					margin-bottom: 30px;

				}
				.item-themes {
					padding-left: 20px;
					li {
						color: #0d0d0d;
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						list-style-type: disc;
					}
				}
			}
		}
	}
	.coffe-break,
	.dinner {
		background-color: transparent;
		.item-number,
		.item-content {
			background-color:transparent;
			padding-top: 28px;
		}
		.item-content {
			.coffee,
			.dinner-text {
				color: #0d0d0d;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				margin-right: 240px;
			}
			.dinner-text {
				margin-right: 200px;
			}
		}

	}
}

.day {
	display: none;
	&.active {
		display: block;
	}
}


.speakers {
	padding-top: 60px;
	.speakers-title {
		margin-bottom: 70px;
		color: #111111;
		font-size: 68px;
		font-weight: 700;
		letter-spacing: -1.36px;
	}
	.speaker {
		width: 288px;
		height: 340px;
		margin-bottom: 70px;
		&:hover {
			.speaker-body {
				transform: translateY(-20px);
				filter: none;
			}
			.hidden-info {
				transform: translateY(-20px);
			}
		}
		.speaker-body {
			height: 340px;
			width: 100%;
			z-index: 10;
			background-size: cover;
			background-position: 50% 0;
			background-repeat: no-repeat;
			position: relative;
			transition: all 0.2s linear;
			&:before {
				content: '';
				display: block;
				width: 100%;
				position: absolute;
				height: 110px;
				background: url(../img/speaker-layer.png) 50% 100% no-repeat;
				background-repeat: repeat-x;
				left: 0;
				bottom: -1px;
			}
		}
		.hidden-info {
			background-color: #292929;
			min-height: 140px;
			-ms-transform: translateY(-150px);
			transform: translateY(-150px);
			z-index: 5;
			position: relative;
			transition: all 0.2s linear;
			.speaker-bio {
				padding-left: 23px;
				padding-right: 30px;
				color: #ffffff;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
			}
		}



		&.oles .speaker-body {background-image: url(../img/speakers2018/oles_new.jpg);}
		&.balakina .speaker-body {background-image: url(../img/speakers2018/balakina_new.jpg);}
		&.bovt .speaker-body {background-image: url(../img/speakers2018/bovt_new.jpg);}
		&.chapman .speaker-body {background-image: url(../img/speakers2018/chapman_new2.jpg);}
		&.drozd  .speaker-body{background-image: url(../img/speakers2018/drozd_new.jpg);}
		&.fedoriv .speaker-body {background-image: url(../img/speakers2018/fedoriv_new.jpg);}
		&.fleisher .speaker-body {background-image: url(../img/speakers2018/fleisher_new.jpg);}
		&.ivanov .speaker-body {background-image: url(../img/speakers2018/ivanov_new.jpg);}
		&.karpyuk .speaker-body {background-image: url(../img/speakers2018/karpyuk_new.jpg);}
		&.kirigetov .speaker-body {background-image: url(../img/speakers2018/kirigetov_new.jpg);}
		&.kravec .speaker-body {background-image: url(../img/speakers2018/kravec_new.jpg);}
		&.krymenish .speaker-body {background-image: url(../img/speakers2018/krymenish.png);}
		&.kyzmin .speaker-body {background-image: url(../img/speakers2018/kuzmin_new.jpg);}
		&.lashkov .speaker-body {background-image: url(../img/speakers2018/lashkov_new.jpg);}
		&.lisenko .speaker-body {background-image: url(../img/speakers2018/lisenko.jpg);}
		&.logvin .speaker-body {background-image: url(../img/speakers2018/logvin_new.jpg);}
		&.mikula .speaker-body {background-image: url(../img/speakers2018/mikula_new.jpg);}
		&.noname .speaker-body {background-image: url(../img/speakers2018/noname_new.jpg);}
		&.plahtiy .speaker-body {background-image: url(../img/speakers2018/plahtiy_new.jpg);}
		&.postrigai .speaker-body {background-image: url(../img/speakers2018/postrigay_new.jpg);}
		&.riabikin .speaker-body {background-image: url(../img/speakers2018/riabikin_new.jpg);}
		&.secret .speaker-body {background-image: url(../img/s12-vl2.png);}
		&.solovev  .speaker-body{background-image: url(../img/speakers2018/solovev_new.jpg);}
		&.zdor .speaker-body {background-image: url(../img/speakers2018/zdor_new.jpg);}

		.speaker-name {
			color: #ffffff;
			font-size: 18px;
			line-height: 21px;
			position: absolute;
			left: 0;
			text-align: center;
			width: 100%;
			text-transform: uppercase;
			bottom: 15px;
			span {
				display: block;
				font-size: 14px;
				line-height: 21px;
				text-transform: none;
			}
		}
	}
}