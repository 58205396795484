@import "common/_mixins.scss";

@include font-face("Roboto", "../fonts/RobotoRegular/RobotoRegular", 400);
@include font-face("Roboto", "../fonts/RobotoMedium/RobotoMedium", 500);
@include font-face("Roboto", "../fonts/RobotoBold/RobotoBold", 700);



@include font-face("Geometria", "../fonts/Geometria-Regular/Geometria-Regular", 400);
@include font-face("Geometria", "../fonts/Geometria-Bold/Geometria-Bold", 700);
@include font-face("Geometria", "../fonts/Geometria-ExtraBold/Geometria-ExtraBold", 800);
@include font-face("Geometria", "../fonts/Geometria-Medium/Geometria-Medium", 500);
@include font-face("Geometria", "../fonts/Geometria-Light/Geometria-Light", 300);

