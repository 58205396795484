@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, video {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot");
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype"), url("../fonts/RobotoRegular/RobotoRegular.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/RobotoMedium/RobotoMedium.eot");
  src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"), url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype"), url("../fonts/RobotoMedium/RobotoMedium.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/RobotoBold/RobotoBold.eot");
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype"), url("../fonts/RobotoBold/RobotoBold.svg#Roboto") format("svg"); }

@font-face {
  font-family: "Geometria";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Geometria-Regular/Geometria-Regular.eot");
  src: url("../fonts/Geometria-Regular/Geometria-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Regular/Geometria-Regular.woff") format("woff"), url("../fonts/Geometria-Regular/Geometria-Regular.ttf") format("truetype"), url("../fonts/Geometria-Regular/Geometria-Regular.svg#Geometria") format("svg"); }

@font-face {
  font-family: "Geometria";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Geometria-Bold/Geometria-Bold.eot");
  src: url("../fonts/Geometria-Bold/Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Bold/Geometria-Bold.woff") format("woff"), url("../fonts/Geometria-Bold/Geometria-Bold.ttf") format("truetype"), url("../fonts/Geometria-Bold/Geometria-Bold.svg#Geometria") format("svg"); }

@font-face {
  font-family: "Geometria";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Geometria-ExtraBold/Geometria-ExtraBold.eot");
  src: url("../fonts/Geometria-ExtraBold/Geometria-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-ExtraBold/Geometria-ExtraBold.woff") format("woff"), url("../fonts/Geometria-ExtraBold/Geometria-ExtraBold.ttf") format("truetype"), url("../fonts/Geometria-ExtraBold/Geometria-ExtraBold.svg#Geometria") format("svg"); }

@font-face {
  font-family: "Geometria";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Geometria-Medium/Geometria-Medium.eot");
  src: url("../fonts/Geometria-Medium/Geometria-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Medium/Geometria-Medium.woff") format("woff"), url("../fonts/Geometria-Medium/Geometria-Medium.ttf") format("truetype"), url("../fonts/Geometria-Medium/Geometria-Medium.svg#Geometria") format("svg"); }

@font-face {
  font-family: "Geometria";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Geometria-Light/Geometria-Light.eot");
  src: url("../fonts/Geometria-Light/Geometria-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Geometria-Light/Geometria-Light.woff") format("woff"), url("../fonts/Geometria-Light/Geometria-Light.ttf") format("truetype"), url("../fonts/Geometria-Light/Geometria-Light.svg#Geometria") format("svg"); }

body {
  font-family: Geometria, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  min-width: 320px;
  color: #000000;
  position: relative;
  background-color: #ffffff; }

video.video {
  min-height: 720px;
  left: 0;
  position: absolute;
  top: 0;
  min-width: 100%;
  background-color: #0f0f0f;
  opacity: 0.5;
  z-index: -1; }

canvas.canvas {
  min-height: 720px;
  left: 0;
  position: absolute;
  top: 0;
  min-width: 100%;
  /* background: #000; */
  z-index: -1; }

#wave1,
#wave2,
#wave3,
#wave4,
#wave5,
#wave6 {
  position: absolute;
  left: 0;
  top: 0; }

#wave3 {
  bottom: 0;
  top: auto; }

*,
*:before,
*:after {
  box-sizing: border-box; }

.hidden {
  display: none; }

.wrapper {
  margin: 0 auto; }

a {
  text-decoration: none; }

form input.error {
  box-shadow: inset 0px 0px 20px 1px rgba(255, 0, 0, 0.3);
  border: 2px solid red !important; }

form input.not_error {
  box-shadow: inset 0px 0px 20px 1px rgba(100, 255, 100, 0.3);
  border: 2px solid #99FF99 !important; }

.slick-prev,
.slick-next {
  z-index: 10; }

.slick-next:before {
  background: url(../img/next.svg) 50% no-repeat;
  color: transparent; }

.slick-prev:before {
  background: url(../img/prev.svg) 50% no-repeat;
  color: transparent; }

.slick-dots {
  bottom: -40px;
  left: 0; }
  .slick-dots li button:before {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #000;
    color: transparent; }
  .slick-dots li.slick-active button:before {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #fff;
    color: transparent;
    border: 2px solid #000000;
    margin-top: -3px; }

@keyframes pulsating {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.pulsating {
  animation-name: pulsating;
  animation-duration: 3s;
  animation-iteration-count: infinite; }

.preloaderGM {
  z-index: 999999999;
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  padding-top: 45vh;
  background: #fff;
  opacity: 1;
  animation: preloader_hide 1s 2s linear forwards, decrease 1s 3s linear forwards;
  -o-animation: preloader_hide 1s 2s linear forwards, decrease 1s 3s linear forwards;
  -moz-animation: preloader_hide 1s 2s linear forwards, decrease 1s 3s linear forwards;
  -ms-animation: preloader_hide 1s 2s linear forwards, decrease 1s 3s linear forwards;
  -webkit-animation: preloader_hide 1s 2s linear forwards, decrease 1s 3s linear forwards; }

.preloaderGM .pre_circles {
  width: 50px;
  height: 50px;
  margin: 0 auto; }

.preloaderGM .pre_circles .pre_circle1 {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #232323;
  will-change: contents;
  animation: circle_grow 1s 1s linear infinite;
  -o-animation: circle_grow 1s 1s linear infinite;
  -webkit-animation: circle_grow 1s 1s linear infinite;
  -moz-animation: circle_grow 1s 1s linear infinite;
  -ms-animation: circle_grow 1s 1s linear infinite;
  opacity: 0; }

.preloaderGM .pre_circles .pre_circle2 {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  width: 0px;
  height: 0px;
  border-radius: 50px;
  border: 2px solid #232323;
  will-change: contents;
  animation: circle_grow 1s 2s linear infinite;
  -o-animation: circle_grow 1s 2s linear infinite;
  -moz-animation: circle_grow 1s 2s linear infinite;
  -ms-animation: circle_grow 1s 2s linear infinite;
  -webkit-animation: circle_grow 1s 2s linear infinite;
  opacity: 0; }

@keyframes decrease {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@-webkit-keyframes decrease {
  0% {
    width: 100%; }
  100% {
    width: 0%; } }

@keyframes circle_grow {
  0% {
    width: 0;
    height: 0;
    margin-left: 25px;
    margin-top: 25px;
    opacity: 1; }
  100% {
    width: 50px;
    height: 50px;
    margin-left: 0px;
    margin-top: 0px;
    opacity: 0; } }

@keyframes preloader_hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes loader_vis {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes circle_grow {
  0% {
    width: 0;
    height: 0;
    margin-left: 25px;
    margin-top: 25px;
    opacity: 1; }
  100% {
    width: 50px;
    height: 50px;
    margin-left: 0px;
    margin-top: 0px;
    opacity: 0; } }

@-webkit-keyframes preloader_hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes loader_vis {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.modal-overlay,
.modal-white {
  display: none;
  padding-top: 1px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  overflow: auto; }
  .modal-overlay .modal-content,
  .modal-white .modal-content {
    margin: 5vh auto;
    width: 60vw;
    padding: 30px;
    margin-bottom: 0px;
    border-radius: 4px;
    height: 70vh; }
  .modal-overlay .close-button,
  .modal-white .close-button {
    position: absolute;
    width: 134px;
    height: 70px;
    top: 0px;
    right: 30px;
    padding: 0px 14px;
    padding-bottom: 0;
    cursor: pointer;
    transition: 0.2s linear;
    background-color: #000;
    padding-top: 5px; }
    .modal-overlay .close-button .one,
    .modal-white .close-button .one {
      margin: 0 auto;
      height: 50px;
      width: 2px;
      background-color: #fff;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transition: 0.2s linear; }
    .modal-overlay .close-button .two,
    .modal-white .close-button .two {
      margin: 0 auto;
      margin-top: -50px;
      height: 50px;
      width: 2px;
      background-color: #fff;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transition: 0.2s linear; }
    .modal-overlay .close-button:hover .one,
    .modal-white .close-button:hover .one {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transition: 0.2s linear;
      background-color: #fff; }
    .modal-overlay .close-button:hover .two,
    .modal-white .close-button:hover .two {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transition: 0.2s linear;
      background-color: #fff; }

#modal-video .modal-content {
  height: auto;
  width: 100%; }

#modal-video .video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  #modal-video .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-white {
  background-color: rgba(243, 243, 243, 0.98);
  background-image: url(../img/modal-white-bg.png);
  background-position: 50% 0;
  background-repeat: no-repeat; }
  .modal-white .schedule {
    padding-top: 70px; }
    .modal-white .schedule .schedule-header {
      margin-bottom: 60px; }
    .modal-white .schedule .schedule-title {
      color: #111111;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.44px;
      text-transform: uppercase; }
      .modal-white .schedule .schedule-title span {
        color: #8848f7; }
    .modal-white .schedule .tabs li {
      display: inline-block; }
      .modal-white .schedule .tabs li:last-child a {
        border: none; }
      .modal-white .schedule .tabs li a {
        padding: 0 40px;
        display: block;
        color: #0d0d0d;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 20px;
        border-right: 1px solid #bbbbbb; }
        .modal-white .schedule .tabs li a span {
          display: block;
          font-size: 22px;
          font-weight: 700;
          line-height: 36px;
          text-transform: uppercase;
          letter-spacing: 0.44px; }
  .modal-white .day .day-title {
    color: #111111;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700;
    letter-spacing: -1.36px;
    position: relative;
    padding-left: 130px;
    margin-bottom: 50px; }
    .modal-white .day .day-title:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: #111; }
  .modal-white .day-item {
    margin-bottom: 2px; }
    .modal-white .day-item .item-number {
      background-color: #ffffff;
      height: 100%;
      padding-left: 17px;
      padding-top: 40px;
      color: #050505;
      font-size: 24px;
      font-weight: 700; }
    .modal-white .day-item .item-content {
      background-color: #fff;
      height: 100%;
      padding-top: 30px;
      padding-right: 60px;
      padding-left: 60px;
      padding-bottom: 50px; }
      .modal-white .day-item .item-content .speaker-info {
        max-width: 225px;
        width: 100%; }
        .modal-white .day-item .item-content .speaker-info .speaker-photo {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-size: cover;
          background-position: 50% 0;
          background-repeat: no-repeat;
          margin-bottom: 10px; }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.oles {
            background-image: url(../img/speakers2018/oles_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.balakina {
            background-image: url(../img/speakers2018/balakina_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.bovt {
            background-image: url(../img/speakers2018/bovt_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.chapman {
            background-image: url(../img/speakers2018/chapman_new2.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.drozd {
            background-image: url(../img/speakers2018/drozd_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.fedoriv {
            background-image: url(../img/speakers2018/fedoriv_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.fleisher {
            background-image: url(../img/speakers2018/fleisher_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.ivanov {
            background-image: url(../img/speakers2018/ivanov_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.karpyuk {
            background-image: url(../img/speakers2018/karpyuk_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.kirigetov {
            background-image: url(../img/speakers2018/kirigetov_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.kravec {
            background-image: url(../img/speakers2018/kravec_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.krymenish {
            background-image: url(../img/speakers2018/krymenish.png); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.kyzmin {
            background-image: url(../img/speakers2018/kuzmin_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.lashkov {
            background-image: url(../img/speakers2018/lashkov_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.lisenko {
            background-image: url(../img/speakers2018/lisenko.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.logvin {
            background-image: url(../img/speakers2018/logvin_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.mikula {
            background-image: url(../img/speakers2018/mikula_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.noname {
            background-image: url(../img/speakers2018/noname_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.plahtiy {
            background-image: url(../img/speakers2018/plahtiy_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.postrigai {
            background-image: url(../img/speakers2018/postrigay_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.riabikin {
            background-image: url(../img/speakers2018/riabikin_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.secret {
            background-image: url(../img/speakers2018/secret_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.solovev {
            background-image: url(../img/speakers2018/solovev_new.jpg); }
          .modal-white .day-item .item-content .speaker-info .speaker-photo.zdor {
            background-image: url(../img/speakers2018/zdor_new.jpg); }
        .modal-white .day-item .item-content .speaker-info .speaker-name {
          color: #0d0d0d;
          font-size: 14px;
          font-weight: 500;
          line-height: 19px; }
      .modal-white .day-item .item-content .time {
        width: 120px;
        color: #0d0d0d;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; }
      .modal-white .day-item .item-content .item-info {
        max-width: 450px;
        width: 100%; }
        .modal-white .day-item .item-content .item-info .item-title {
          color: #6622db;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 30px; }
        .modal-white .day-item .item-content .item-info .item-themes {
          padding-left: 20px; }
          .modal-white .day-item .item-content .item-info .item-themes li {
            color: #0d0d0d;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            list-style-type: disc; }
  .modal-white .coffe-break,
  .modal-white .dinner {
    background-color: transparent; }
    .modal-white .coffe-break .item-number,
    .modal-white .coffe-break .item-content,
    .modal-white .dinner .item-number,
    .modal-white .dinner .item-content {
      background-color: transparent;
      padding-top: 28px; }
    .modal-white .coffe-break .item-content .coffee,
    .modal-white .coffe-break .item-content .dinner-text,
    .modal-white .dinner .item-content .coffee,
    .modal-white .dinner .item-content .dinner-text {
      color: #0d0d0d;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-right: 240px; }
    .modal-white .coffe-break .item-content .dinner-text,
    .modal-white .dinner .item-content .dinner-text {
      margin-right: 200px; }

.day {
  display: none; }
  .day.active {
    display: block; }

.speakers {
  padding-top: 60px; }
  .speakers .speakers-title {
    margin-bottom: 70px;
    color: #111111;
    font-size: 68px;
    font-weight: 700;
    letter-spacing: -1.36px; }
  .speakers .speaker {
    width: 288px;
    height: 340px;
    margin-bottom: 70px; }
    .speakers .speaker:hover .speaker-body {
      transform: translateY(-20px);
      filter: none; }
    .speakers .speaker:hover .hidden-info {
      transform: translateY(-20px); }
    .speakers .speaker .speaker-body {
      height: 340px;
      width: 100%;
      z-index: 10;
      background-size: cover;
      background-position: 50% 0;
      background-repeat: no-repeat;
      position: relative;
      transition: all 0.2s linear; }
      .speakers .speaker .speaker-body:before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        height: 110px;
        background: url(../img/speaker-layer.png) 50% 100% no-repeat;
        background-repeat: repeat-x;
        left: 0;
        bottom: -1px; }
    .speakers .speaker .hidden-info {
      background-color: #292929;
      min-height: 140px;
      -ms-transform: translateY(-150px);
      transform: translateY(-150px);
      z-index: 5;
      position: relative;
      transition: all 0.2s linear; }
      .speakers .speaker .hidden-info .speaker-bio {
        padding-left: 23px;
        padding-right: 30px;
        color: #ffffff;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; }
    .speakers .speaker.oles .speaker-body {
      background-image: url(../img/speakers2018/oles_new.jpg); }
    .speakers .speaker.balakina .speaker-body {
      background-image: url(../img/speakers2018/balakina_new.jpg); }
    .speakers .speaker.bovt .speaker-body {
      background-image: url(../img/speakers2018/bovt_new.jpg); }
    .speakers .speaker.chapman .speaker-body {
      background-image: url(../img/speakers2018/chapman_new2.jpg); }
    .speakers .speaker.drozd .speaker-body {
      background-image: url(../img/speakers2018/drozd_new.jpg); }
    .speakers .speaker.fedoriv .speaker-body {
      background-image: url(../img/speakers2018/fedoriv_new.jpg); }
    .speakers .speaker.fleisher .speaker-body {
      background-image: url(../img/speakers2018/fleisher_new.jpg); }
    .speakers .speaker.ivanov .speaker-body {
      background-image: url(../img/speakers2018/ivanov_new.jpg); }
    .speakers .speaker.karpyuk .speaker-body {
      background-image: url(../img/speakers2018/karpyuk_new.jpg); }
    .speakers .speaker.kirigetov .speaker-body {
      background-image: url(../img/speakers2018/kirigetov_new.jpg); }
    .speakers .speaker.kravec .speaker-body {
      background-image: url(../img/speakers2018/kravec_new.jpg); }
    .speakers .speaker.krymenish .speaker-body {
      background-image: url(../img/speakers2018/krymenish.png); }
    .speakers .speaker.kyzmin .speaker-body {
      background-image: url(../img/speakers2018/kuzmin_new.jpg); }
    .speakers .speaker.lashkov .speaker-body {
      background-image: url(../img/speakers2018/lashkov_new.jpg); }
    .speakers .speaker.lisenko .speaker-body {
      background-image: url(../img/speakers2018/lisenko.jpg); }
    .speakers .speaker.logvin .speaker-body {
      background-image: url(../img/speakers2018/logvin_new.jpg); }
    .speakers .speaker.mikula .speaker-body {
      background-image: url(../img/speakers2018/mikula_new.jpg); }
    .speakers .speaker.noname .speaker-body {
      background-image: url(../img/speakers2018/noname_new.jpg); }
    .speakers .speaker.plahtiy .speaker-body {
      background-image: url(../img/speakers2018/plahtiy_new.jpg); }
    .speakers .speaker.postrigai .speaker-body {
      background-image: url(../img/speakers2018/postrigay_new.jpg); }
    .speakers .speaker.riabikin .speaker-body {
      background-image: url(../img/speakers2018/riabikin_new.jpg); }
    .speakers .speaker.secret .speaker-body {
      background-image: url(../img/s12-vl2.png); }
    .speakers .speaker.solovev .speaker-body {
      background-image: url(../img/speakers2018/solovev_new.jpg); }
    .speakers .speaker.zdor .speaker-body {
      background-image: url(../img/speakers2018/zdor_new.jpg); }
    .speakers .speaker .speaker-name {
      color: #ffffff;
      font-size: 18px;
      line-height: 21px;
      position: absolute;
      left: 0;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      bottom: 15px; }
      .speakers .speaker .speaker-name span {
        display: block;
        font-size: 14px;
        line-height: 21px;
        text-transform: none; }

/*Параметры контейнера */
.countdownHolder {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  margin-top: -17px;
  font: 24px/1.5 Geometria, sans-serif;
  text-align: center;
  letter-spacing: -3px;
  /*Параметры цифр */
  /*Параметры подложки для секций */
  /*Параметры секций разделения (:)*/
  /*Параметры вспомогательного текста (дни, часы, минуты, секунды)*/ }
  .countdownHolder .position {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 0.65em;
    height: 2em; }
  .countdownHolder .digit {
    position: absolute;
    display: block;
    width: .5em;
    border-radius: .2em;
    text-align: center;
    color: #fff;
    letter-spacing: -1px; }
    .countdownHolder .digit.static {
      margin-top: 18px; }
  .countdownHolder .countDays,
  .countdownHolder .countHours,
  .countdownHolder .countMinutes,
  .countdownHolder .countSeconds {
    background: none;
    padding: 10px; }
  .countdownHolder .countDiv {
    display: none;
    width: 16px;
    height: 1.6em;
    position: relative; }
    .countdownHolder .countDiv:before, .countdownHolder .countDiv:after {
      position: absolute;
      width: 5px;
      height: 5px;
      background-color: #444;
      border-radius: 50%;
      left: 50%;
      margin-left: -3px;
      top: 0.5em;
      box-shadow: 1px 1px 1px rgba(4, 4, 4, 0.5);
      content: ''; }
    .countdownHolder .countDiv:after {
      top: 0.9em; }
  .countdownHolder .textdigit {
    font-size: 15px;
    font-family: Geometria;
    letter-spacing: 0px;
    color: #fff; }

.vaucher-btn {
  border-radius: 2px;
  background-image: linear-gradient(-235deg, #fc134c 0%, #fe1fa0 65%, #fc25d8 100%);
  width: 158px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase; }

.btn {
  max-width: 429px;
  width: 100%;
  border-radius: 2px;
  background-image: linear-gradient(-200deg, #fc134c 0%, #fe1fa0 65%, #fc25d8 100%);
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  line-height: 75px;
  letter-spacing: 0.17px;
  text-align: center; }

.getvaucher {
  padding-right: 50px; }
  .getvaucher span {
    width: 50px;
    height: 40px;
    position: absolute;
    background: url(../img/tickets.svg) 50% no-repeat;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% - 80px); }

.subm {
  cursor: pointer;
  border: none;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  line-height: 83px;
  text-transform: uppercase; }
  .subm span {
    left: calc(50% + 170px); }

.header {
  background-color: #000;
  padding: 13px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20; }
  .header .header-menu li {
    display: inline-block;
    margin-right: 20px; }
    .header .header-menu li:last-child {
      margin-right: 0; }
  .header .header-menu .menu-link {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s linear; }
    .header .header-menu .menu-link:hover {
      color: #f3045d; }
  .header .phone {
    color: #ffffff;
    font-size: 15px;
    font-weight: 700; }
  .header .mobile-menu-icon {
    display: none;
    color: rgba(255, 255, 255, 0.57);
    font-size: 14px;
    font-weight: 400;
    background: url(../img/mob-menu-icon.svg) 50% 0 no-repeat;
    width: 40px;
    text-align: center;
    padding-top: 20px; }
  .header .timer-wrap {
    background: #000;
    height: 65px;
    width: 100%;
    max-width: 685px;
    transform: translate(0, -65px);
    transition: 0.4s linear;
    position: absolute;
    top: 0px;
    left: calc(50% - 440px);
    z-index: 99;
    text-align: center; }
    .header .timer-wrap.active {
      transform: translate(0, 0); }
  .header .timer-title {
    padding-top: 5px;
    display: inline-block;
    max-width: 330px;
    text-align: left;
    color: #fff;
    font-size: 23px;
    line-height: 28px;
    vertical-align: top;
    text-transform: uppercase; }

form.standart-form {
  text-align: center;
  position: relative; }
  form.standart-form .part {
    margin: 0 auto;
    display: inline-block;
    min-width: 300px; }
  form.standart-form .label {
    text-align: left;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px; }
  form.standart-form input.name,
  form.standart-form input.email,
  form.standart-form input.phone {
    width: 398px;
    max-width: 398px;
    height: 45px;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 45px;
    margin-bottom: 15px;
    display: block; }
  form.standart-form:before {
    content: "";
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.7) url(../img/loading.svg) 50% no-repeat;
    z-index: 10; }
  form.standart-form.loading:before {
    display: block; }
  form.standart-form .part2 {
    margin-left: 18px;
    margin-right: 18px; }
  form.standart-form .subm {
    max-width: 100%; }

.sec1 {
  padding-top: 145px;
  background: #000 url(../img/sec1.png) 50% 0 no-repeat;
  padding-bottom: 115px;
  position: relative;
  overflow: hidden; }
  .sec1 video.video {
    opacity: 1; }
  .sec1 .date {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 27px; }
    .sec1 .date span {
      color: #8848f7;
      text-transform: uppercase;
      font-weight: 700; }
  .sec1 .title {
    color: #ffffff;
    font-size: 94px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: -1.88px;
    max-width: 820px;
    margin-bottom: 31px; }
    .sec1 .title span {
      color: #8848f7;
      line-height: 92px;
      letter-spacing: -2.82px; }
  .sec1 .changing_title {
    transition: .2s linear;
    transform: translateY(0);
    opacity: 1; }
    .sec1 .changing_title.hidden {
      transform: translateY(-20px);
      opacity: 0; }
  .sec1 .desc {
    color: #ffffff;
    font-size: 20px;
    line-height: 28px;
    margin-left: 257px;
    margin-top: -115px;
    margin-bottom: 80px; }
    .sec1 .desc span {
      color: #b286ff;
      font-weight: 700;
      display: inline-block;
      background-color: #0e0e0e;
      padding: 0 5px; }
  .sec1 .presale-title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    margin-bottom: 30px; }
  .sec1 .btn {
    margin-right: 28px; }
  .sec1 .btn-video {
    margin-right: 0;
    border: 2px solid #ffffff;
    background-color: transparent;
    background-image: url(../img/btn-play-icon.svg);
    background-repeat: no-repeat;
    background-position: calc(50% + 130px) 50%; }

.sec2 {
  padding: 20px 0; }
  .sec2 .about-tilte {
    color: #808080;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; }
  .sec2 .about-logos {
    max-width: 853px;
    width: 100%;
    height: 92px;
    background: url(../img/about-logos.png) 50% no-repeat; }

.sec3 {
  background-color: #f6f6f6;
  padding-top: 90px;
  padding-bottom: 70px; }
  .sec3 .item {
    width: 260px;
    margin-bottom: 70px; }
    .sec3 .item .text {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      color: #0d0d0d; }
    .sec3 .item .number {
      color: #8848f7;
      font-size: 68px;
      line-height: 50px;
      font-weight: 800;
      position: relative;
      margin-bottom: 20px; }
      .sec3 .item .number:after {
        content: attr(data-inf);
        color: #020202;
        font-size: 24px;
        display: inline-block;
        vertical-align: top; }
  .sec3 .more-items {
    display: block;
    text-align: center;
    width: 130px;
    opacity: 0.8;
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    margin: 0 auto; }
  .sec3 .items-hidden {
    display: none; }
    .sec3 .items-hidden .item:nth-last-child(1), .sec3 .items-hidden .item:nth-last-child(2), .sec3 .items-hidden .item:nth-last-child(3) {
      margin-bottom: 0; }

.sec4 {
  background: url(../img/sec4.jpg) 50% 0 no-repeat;
  padding-top: 100px;
  position: relative;
  overflow: hidden; }
  .sec4 .sec-title {
    color: #000000;
    font-size: 68px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -1.36px;
    max-width: 560px;
    margin-bottom: 74px; }
    .sec4 .sec-title span {
      color: #8848f7; }
  .sec4 .text {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    max-width: 425px; }
    .sec4 .text b {
      font-weight: 700; }
  .sec4 .text2 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-width: 400px;
    box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
    padding: 60px 49px;
    margin-left: -11px;
    overflow: hidden;
    position: relative;
    background-color: #000; }
  .sec4 .text3 {
    width: 100%;
    max-width: 575px;
    position: absolute;
    color: #ffffff;
    font-size: 16px;
    bottom: 0;
    transform: translateX(389px);
    padding-left: 32px;
    padding-bottom: 45px;
    padding-top: 50px;
    background: url(../img/s4-t3-bg.png) 50% no-repeat;
    background-size: cover; }
    .sec4 .text3 b {
      display: block;
      color: #ffffff;
      font-size: 48px;
      font-weight: 800;
      line-height: 40px; }
      .sec4 .text3 b span {
        font-size: 31px; }

.sec5 {
  background: url(../img/sec5.jpg) 50% 0 no-repeat;
  padding-bottom: 132px; }
  .sec5 .top-text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-width: 620px;
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 75px;
    padding-right: 125px;
    box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
    background-color: #000;
    background-position: 100% 0;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden; }
  .sec5 .block-title {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 80px; }
  .sec5 .slider-trends {
    box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
    background-color: #ffffff;
    height: 505px;
    max-width: 487px;
    margin: 0 auto;
    padding-top: 57px;
    padding-left: 70px;
    padding-right: 25px; }
    .sec5 .slider-trends .slick-dots {
      bottom: 42px; }
    .sec5 .slider-trends .slick-prev {
      top: auto;
      bottom: 40px;
      left: 60px;
      z-index: 10; }
    .sec5 .slider-trends .slick-next {
      top: auto;
      bottom: 40px;
      right: 60px;
      z-index: 10; }
  .sec5 .trend {
    color: #000000;
    font-size: 35px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.7px;
    padding-top: 120px;
    background-position: 0 0;
    background-repeat: no-repeat; }
    .sec5 .trend span {
      color: #8848f7; }
  .sec5 .trend1 {
    background-image: url(../img/trend1-icon.png); }
  .sec5 .trend2 {
    background-image: url(../img/trend2-icon.png); }
  .sec5 .trend3 {
    background-image: url(../img/trend3-icon.png); }
  .sec5 .trend4 {
    background-image: url(../img/trend4-icon.png); }
  .sec5 .trend5 {
    background-image: url(../img/trend5-icon.png); }
  .sec5 .trend6 {
    background-image: url(../img/trend6-icon.png); }
  .sec5 .block-desc {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    max-width: 400px;
    margin-bottom: 70px; }

.sec6 {
  padding: 120px 0; }
  .sec6 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 70px;
    font-weight: 700;
    letter-spacing: -1.36px;
    margin-bottom: 45px; }
  .sec6 .video {
    position: relative;
    height: 552px;
    background-color: #000; }
    .sec6 .video .play {
      background-image: url(../img/play.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: calc(50% - 51px);
      left: calc(50% - 51px);
      width: 102px;
      height: 102px;
      border-radius: 50%;
      display: block; }
    .sec6 .video .author-name {
      position: absolute;
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      bottom: 0px;
      left: 35px;
      min-height: 40px; }
      .sec6 .video .author-name a {
        color: #fff;
        text-decoration: underline; }
  .sec6 .video-sm {
    height: 269px;
    margin-bottom: 13px;
    width: 300px; }
    .sec6 .video-sm .play {
      width: 70px;
      height: 70px;
      background-image: url(../img/play-sm.svg);
      top: calc(50% - 35px);
      left: calc(50% - 35px); }
    .sec6 .video-sm .author-name {
      bottom: 5px;
      left: 15px;
      min-height: 40px; }
  .sec6 .slider-vedeoreviews .slick-dots {
    bottom: -52px; }
  .sec6 .slider-vedeoreviews .slick-prev {
    top: auto;
    left: calc(50% - 150px);
    bottom: -55px; }
  .sec6 .slider-vedeoreviews .slick-next {
    top: auto;
    right: calc(50% - 150px);
    bottom: -55px; }
  .sec6 .video {
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative; }
    .sec6 .video:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      height: 110px;
      background: url(../img/speaker-layer.png) 50% 100% no-repeat;
      background-repeat: repeat-x;
      left: 0;
      bottom: -1px; }
    .sec6 .video.vr1 {
      background-image: url(../img/reviews/vr1.jpg); }
    .sec6 .video.vr2 {
      background-image: url(../img/reviews/vr2.jpg); }
    .sec6 .video.vr3 {
      background-image: url(../img/reviews/vr3.jpg); }
    .sec6 .video.vr4 {
      background-image: url(../img/reviews/vr4.jpg); }
    .sec6 .video.vr5 {
      background-image: url(../img/reviews/vr5.jpg); }
    .sec6 .video.vr6 {
      background-image: url(../img/reviews/vr6.jpg); }
    .sec6 .video.vr7 {
      background-image: url(../img/reviews/vr7.jpg); }
    .sec6 .video.vr8 {
      background-image: url(../img/reviews/vr8.jpg); }
    .sec6 .video.vr9 {
      background-image: url(../img/reviews/vr9.jpg); }
    .sec6 .video.vr10 {
      background-image: url(../img/reviews/vr10.jpg); }

.sec7 {
  background-color: #f6f6f6;
  padding-top: 90px;
  overflow: hidden; }
  .sec7 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 78px;
    font-weight: 700;
    letter-spacing: -1.36px;
    margin-bottom: 53px; }
  .sec7 .part {
    width: 50%; }
  .sec7 .block-white {
    background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 60px;
    padding-right: 75px; }
    .sec7 .block-white .text {
      color: #0d0d0d;
      font-size: 16px;
      line-height: 22px; }
      .sec7 .block-white .text b {
        font-weight: 700; }
  .sec7 .block-black {
    background-color: #171717;
    padding-top: 55px;
    padding-bottom: 55px;
    padding-left: 60px;
    padding-right: 70px;
    position: relative;
    overflow: hidden; }
    .sec7 .block-black .wave-wrap {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      height: 150px;
      overflow: hidden; }
    .sec7 .block-black .block-title {
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 25px; }
    .sec7 .block-black ul li {
      color: #ffffff;
      font-size: 16px;
      line-height: 22px;
      padding-left: 33px;
      margin-bottom: 20px;
      background: url(../img/teaser-open-icon.svg) 0 3px no-repeat;
      cursor: pointer;
      transition: all 0.2s linear;
      position: relative; }
      .sec7 .block-black ul li:after {
        content: '';
        display: block;
        width: 200px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #171717 100%);
        position: absolute;
        right: 0;
        top: 0; }
      .sec7 .block-black ul li span {
        display: none; }
      .sec7 .block-black ul li.active {
        transition: all 0.2s linear;
        background: url(../img/teaser-close-icon.svg) 0 3px no-repeat; }
        .sec7 .block-black ul li.active:after {
          display: none; }
    .sec7 .block-black .text {
      color: #ffffff;
      font-size: 16px;
      line-height: 22px; }
  .sec7 .part2 {
    position: relative; }
    .sec7 .part2:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2000px;
      background: url(../img/speakers-bg.png) 0 0 no-repeat;
      background-size: cover;
      transition: all 0.2s linear; }
  .sec7 .lob18-speakers-block {
    text-align: center;
    width: 100%;
    max-width: 505px;
    height: 563px;
    background: url(../img/lob18-spekers-bg.png) 50% no-repeat;
    background-size: cover;
    position: relative;
    display: inline-block;
    padding-top: 100px; }
    .sec7 .lob18-speakers-block img {
      margin-bottom: 60px; }
    .sec7 .lob18-speakers-block .block-text {
      display: block;
      color: #ffffff;
      font-size: 35px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.7px; }
      .sec7 .lob18-speakers-block .block-text:after {
        content: '';
        display: block;
        width: 55px;
        height: 56px;
        border-radius: 50%;
        background: url(../img/arr-white.svg) 50% no-repeat;
        margin: 0 auto;
        margin-top: 60px; }
      .sec7 .lob18-speakers-block .block-text span {
        display: block;
        text-decoration: underline; }

.sec8 {
  background-color: #f6f6f6;
  padding: 100px 0; }
  .sec8 .sec-title {
    color: #111111;
    font-size: 48px;
    line-height: 57px;
    font-weight: 700;
    letter-spacing: -0.96px;
    max-width: 880px;
    margin-bottom: 55px; }
  .sec8 .slider-reviews {
    max-width: 960px;
    margin: 0 auto; }
    .sec8 .slider-reviews .slick-dots {
      bottom: -72px; }
    .sec8 .slider-reviews .slick-prev {
      left: calc(50% - 550px);
      width: 40px;
      height: 40px; }
      .sec8 .slider-reviews .slick-prev:before {
        font-size: 40px;
        background-size: 100%; }
    .sec8 .slider-reviews .slick-next {
      right: calc(50% - 550px);
      width: 40px;
      height: 40px; }
      .sec8 .slider-reviews .slick-next:before {
        font-size: 40px;
        background-size: 100%; }

.sec9 {
  overflow: hidden; }
  .sec9 .part {
    width: 50%; }
  .sec9 .part1 {
    padding: 100px 0; }
  .sec9 .sec-title {
    color: #060606;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700;
    letter-spacing: -1.36px;
    margin-bottom: 50px; }
  .sec9 .text {
    color: #060606;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 460px;
    margin-bottom: 60px; }
  .sec9 .bottom-text {
    color: #050505;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    max-width: 380px; }
  .sec9 .part2 {
    position: relative;
    padding-top: 140px; }
    .sec9 .part2:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2000px;
      background: url(../img/schedule-bg.png) 0 0 no-repeat;
      background-size: cover; }
  .sec9 .lob18-schedule-block {
    width: 100%;
    max-width: 505px;
    height: 505px;
    background: url(../img/lob18-spekers-bg.png) 50% no-repeat;
    position: relative;
    padding-left: 100px;
    padding-top: 65px; }
    .sec9 .lob18-schedule-block .block-title {
      color: #ffffff;
      font-size: 35px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.7px;
      margin-bottom: 30px; }
    .sec9 .lob18-schedule-block .days-images {
      height: 130px;
      background: url(../img/days-images.png) 0 0 no-repeat;
      margin-bottom: 55px; }
    .sec9 .lob18-schedule-block .schedule18-link {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-decoration: underline;
      position: relative;
      display: block; }
      .sec9 .lob18-schedule-block .schedule18-link .arr {
        display: block;
        position: absolute;
        width: 57px;
        height: 57px;
        border-radius: 56px;
        background: url(../img/arr-white.svg) 50% no-repeat;
        right: 46px;
        top: 0; }

.sec10 {
  padding-top: 90px;
  padding-bottom: 80px;
  overflow: hidden;
  background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
  background-color: #000;
  position: relative; }
  .sec10 .wave-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 200px; }
  .sec10 .vaucher-title {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.96px; }
  .sec10 .open-sale-block {
    background: #892eca url(../img/open-sale-block-bg.jpg) 50% no-repeat;
    box-shadow: 0 0 90px rgba(0, 0, 0, 0.29);
    background-size: cover;
    width: 418px;
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 135px;
    padding-right: 100px;
    position: relative;
    margin-bottom: 100px; }
    .sec10 .open-sale-block:before {
      content: '';
      display: block;
      position: absolute;
      top: -60px;
      left: 180px;
      background: url(../img/open-sale-block-lines.png) 0 0 no-repeat;
      width: 300px;
      height: 290px; }
    .sec10 .open-sale-block .open-sale-info {
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px; }

.sec11 {
  background: #010a13 url(../img/sec11.jpg) 50% 0 no-repeat;
  padding-top: 200px;
  padding-bottom: 230px;
  overflow: hidden; }
  .sec11 .sec-title {
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
    font-size: 48px;
    line-height: 66px;
    font-weight: 700;
    letter-spacing: -0.96px;
    position: relative;
    margin-right: 290px; }
    .sec11 .sec-title:before {
      content: '';
      display: block;
      position: absolute;
      width: 544px;
      height: 528px;
      background: url(../img/s11-lines.png) 0 0 no-repeat;
      top: -180px;
      left: 64px; }
    .sec11 .sec-title b {
      display: block;
      color: #ffffff;
      font-size: 86px;
      position: relative;
      z-index: 1; }
      .sec11 .sec-title b:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 104px 104px 0 0;
        border-color: #7053ff transparent transparent transparent;
        left: 159px;
        z-index: -1; }
  .sec11 .lob18-link {
    margin-top: 38px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    max-width: 325px;
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .sec11 .lob18-link span {
      display: block;
      width: 102px;
      height: 102px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -137px;
      border: 4px solid #ffffff;
      background: url(../img/play-triangle.svg) 38px 50% no-repeat; }

.sec12 {
  background-color: #f6f6f6;
  padding-top: 100px;
  padding-bottom: 100px; }
  .sec12 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700;
    letter-spacing: -1.36px;
    margin-bottom: 45px; }
  .sec12 .text-block {
    background-color: rgba(255, 255, 255, 0.84);
    padding: 64px; }
    .sec12 .text-block .text1 {
      max-width: 528px;
      color: #0d0d0d;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 43px; }
      .sec12 .text-block .text1 b {
        font-weight: 700; }
    .sec12 .text-block .vaucher-title {
      color: #050505;
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      margin-bottom: 25px; }
    .sec12 .text-block .text2 {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      max-width: 440px;
      margin-bottom: 40px; }
  .sec12 .btn {
    max-width: 500px; }
  .sec12 .slider-video .videolink {
    width: 100%;
    height: 300px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 16px;
    position: relative; }
    .sec12 .slider-video .videolink .name {
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      position: absolute;
      bottom: 20px;
      left: 20px; }
    .sec12 .slider-video .videolink .play {
      width: 87px;
      height: 87px;
      border-radius: 50%;
      border: 4px solid #ffffff;
      display: block;
      position: absolute;
      left: calc(50% - 43px);
      top: calc(50% - 43px);
      background: url(../img/play-triangle.svg) calc(50% + 3px) 50% no-repeat; }
  .sec12 .slider-video .vl1 {
    background-image: url(../img/s12-vl1.png); }
  .sec12 .slider-video .vl2 {
    background-image: url(../img/s12-vl2.png); }
  .sec12 .slider-video .vl3 {
    background-image: url(../img/s12-vl3.jpg); }
  .sec12 .slider-video .vl4 {
    background-image: url(../img/s12-vl4.jpg); }
  .sec12 .slider-video .slick-dots {
    bottom: -72px; }
  .sec12 .slider-video .slick-prev {
    top: auto;
    left: calc(50% - 150px);
    bottom: -75px; }
  .sec12 .slider-video .slick-next {
    top: auto;
    right: calc(50% - 150px);
    bottom: -75px; }

.sec13 {
  padding-top: 90px;
  padding-bottom: 100px; }
  .sec13 .sec-title {
    color: #020202;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    letter-spacing: -0.96px;
    margin-bottom: 70px; }
  .sec13 .company-logos {
    height: 400px;
    background: url(../img/compay-logos.png) 50% 0 no-repeat; }
  .sec13 .slider .partner {
    width: 150px;
    height: 150px;
    margin: 0 auto; }

.sec14 {
  background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
  overflow: hidden;
  position: relative; }
  .sec14 .wave-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 200px; }
  .sec14 .sec-title {
    color: #ffffff;
    font-size: 71px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -1.43px; }
    .sec14 .sec-title span {
      position: relative;
      display: inline-block; }
      .sec14 .sec-title span:after {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        position: absolute;
        right: -45px;
        top: -5px;
        background: url(../img/star.svg) 50% no-repeat; }
  .sec14 .photos1 {
    height: 453px;
    background: url(../img/photos1.png) 100% 0 no-repeat; }
  .sec14 .white-block {
    box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
    background-color: #ffffff;
    padding-bottom: 70px;
    padding-top: 70px; }
    .sec14 .white-block .text1 {
      padding-left: 58px;
      max-width: 470px;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 50px; }
      .sec14 .white-block .text1 b {
        font-weight: 700; }
    .sec14 .white-block .text2 {
      color: #050505;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      padding-left: 57px; }
    .sec14 .white-block .text3 {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      max-width: 450px;
      margin-bottom: 65px; }
    .sec14 .white-block .btn {
      max-width: 387px; }
  .sec14 .photos2 {
    height: 775px;
    background: url(../img/photos2.png) 100% 0 no-repeat;
    position: relative; }
    .sec14 .photos2 .video {
      position: absolute;
      top: 210px;
      right: 0;
      background: url(../img/photos2-video.jpg) 50% 0 no-repeat;
      background-size: cover;
      width: 300px;
      height: 205px; }
      .sec14 .photos2 .video .play {
        width: 70px;
        height: 70px;
        background-image: url(../img/play-sm.svg);
        top: calc(50% - 35px);
        left: calc(50% - 35px);
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 50%;
        display: block; }
  .sec14 .party-title {
    color: #ffffff;
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    max-width: 490px;
    margin-bottom: 62px; }
  .sec14 .party-text {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    max-width: 475px;
    margin-bottom: 70px; }
    .sec14 .party-text b {
      font-weight: 700; }
  .sec14 .btn span {
    font-size: 27px;
    display: inline-block;
    margin-left: 10px; }

.sec15 {
  padding: 80px 0; }
  .sec15 .sec-title {
    color: #111111;
    font-size: 68px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: -1.36px;
    margin-bottom: 45px; }
  .sec15 .item-wrap {
    height: 540px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover; }
  .sec15 .iw1 {
    background-image: url(../img/iw1.jpg); }
  .sec15 .iw2 {
    background-image: url(../img/iw2.jpg); }
    .sec15 .iw2 .item .item-text {
      max-width: 250px; }
  .sec15 .iw3 {
    background-image: url(../img/iw3.jpg); }
    .sec15 .iw3 .item .item-text {
      max-width: 250px; }
  .sec15 .item {
    width: 50%;
    height: 100%;
    background-image: linear-gradient(to left, #9a39e6 0%, #6735c4 100%);
    opacity: 0.97; }
    .sec15 .item .item-text {
      color: #ffffff;
      font-size: 16px;
      line-height: 22px;
      max-width: 300px;
      position: relative; }
      .sec15 .item .item-text:before {
        content: attr(data-number);
        display: block;
        text-align: center;
        color: #ffffff;
        font-size: 86px;
        line-height: 80px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 30px; }

.sec16 {
  position: relative;
  overflow: hidden;
  height: 1073px;
  padding-top: 90px;
  padding-top: 50px;
  padding-bottom: 130px; }
  .sec16 #inner-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: table;
    width: 100%;
    height: 400px;
    min-height: 322px;
    overflow: visible; }
  .sec16 .waves {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    min-width: 100%;
    min-height: 70%;
    margin: auto 0; }
  .sec16:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    background-color: #000;
    top: 0;
    left: 0; }
  .sec16 .sec-title {
    color: #ffffff;
    font-size: 94px;
    line-height: 105px;
    font-weight: 700;
    letter-spacing: -1.88px;
    margin-bottom: 100px;
    padding-left: 105px; }
    .sec16 .sec-title span {
      position: relative; }
  .sec16 .white-block {
    background-color: #ffffff;
    padding: 70px 55px;
    max-width: 1114px;
    margin: 0 auto;
    margin-bottom: 85px; }
    .sec16 .white-block .text {
      color: #0e0e0e;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      max-width: 475px; }
      .sec16 .white-block .text b {
        font-weight: 700; }
    .sec16 .white-block .text-big {
      color: #050505;
      font-size: 24px;
      line-height: 30px;
      font-weight: 800;
      max-width: 320px;
      margin: 45px 0; }
    .sec16 .white-block .text2 {
      max-width: 435px; }
  .sec16 .bottom-block {
    max-width: 1114px;
    margin: 0 auto; }
    .sec16 .bottom-block .block-title {
      color: #ffffff;
      font-size: 24px;
      line-height: 31px;
      font-weight: 700;
      max-width: 170px; }
    .sec16 .bottom-block ul li {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px; }
  .sec16 .btn {
    width: 500px;
    line-height: 83px; }

.sec17 {
  padding-top: 90px;
  padding-bottom: 145px; }
  .sec17 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700;
    letter-spacing: -1.36px;
    max-width: 950px;
    margin-bottom: 60px; }
  .sec17 .slider-gallery .slick-dots {
    bottom: -72px; }
  .sec17 .slider-gallery .slick-prev {
    top: auto;
    left: calc(50% - 150px);
    bottom: -75px; }
  .sec17 .slider-gallery .slick-next {
    top: auto;
    right: calc(50% - 150px);
    bottom: -75px; }
  .sec17 .slide {
    height: 823px;
    position: relative; }
  .sec17 .photo {
    position: absolute;
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat; }
  .sec17 .photo1,
  .sec17 .photo9 {
    left: 0;
    top: 0;
    width: 405px;
    height: 265px;
    background-image: url(../img/gallery/1.jpg); }
  .sec17 .photo2,
  .sec17 .photo10 {
    left: 0;
    top: 280px;
    width: 405px;
    height: 265px;
    background-image: url(../img/gallery/2.jpg); }
  .sec17 .photo3,
  .sec17 .photo11 {
    left: 0;
    bottom: 0;
    width: 310px;
    height: 265px;
    background-image: url(../img/gallery/3.jpg); }
  .sec17 .photo4,
  .sec17 .photo12 {
    left: 420px;
    top: 0;
    width: 570px;
    height: 545px;
    background-image: url(../img/gallery/4.jpg); }
  .sec17 .photo5,
  .sec17 .photo13 {
    left: 324px;
    bottom: 0;
    width: 500px;
    height: 265px;
    background-image: url(../img/gallery/5.jpg); }
  .sec17 .photo6,
  .sec17 .photo14 {
    right: 0;
    top: 0;
    width: 235px;
    height: 265px;
    background-image: url(../img/gallery/6.jpg); }
  .sec17 .photo7,
  .sec17 .photo15 {
    right: 0;
    top: 280px;
    width: 235px;
    height: 265px;
    background-image: url(../img/gallery/7.jpg); }
  .sec17 .photo8,
  .sec17 .photo16 {
    right: 0;
    bottom: 0;
    width: 405px;
    height: 265px;
    background-image: url(../img/gallery/8.jpg); }
  .sec17 .photo9 {
    background-image: url(../img/gallery/9.jpg); }
  .sec17 .photo10 {
    background-image: url(../img/gallery/10.jpg); }
  .sec17 .photo11 {
    background-image: url(../img/gallery/11.jpg); }
  .sec17 .photo12 {
    background-image: url(../img/gallery/12.jpg); }
  .sec17 .photo13 {
    background-image: url(../img/gallery/13.jpg); }
  .sec17 .photo14 {
    background-image: url(../img/gallery/14.jpg); }
  .sec17 .photo15 {
    background-image: url(../img/gallery/15.jpg); }
  .sec17 .photo16 {
    background-image: url(../img/gallery/16.jpg); }

.sec18 {
  padding-top: 180px;
  padding-bottom: 80px;
  position: relative; }
  .sec18 .bird {
    position: absolute;
    width: 350px;
    height: 390px;
    background: url(../img/bird.png) 50% no-repeat;
    top: 0;
    left: calc(50% - 300px); }
  .sec18 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700; }
    .sec18 .sec-title span {
      display: block;
      position: relative; }
      .sec18 .sec-title span:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 104px 104px 0 0;
        border-color: #a340f4 transparent transparent transparent;
        left: 166px;
        z-index: -1; }
  .sec18 .text {
    color: #0d0d0d;
    font-size: 16px;
    line-height: 24px; }

.sec19 {
  background-image: linear-gradient(to right, #3b2281 0%, #3b2281 34%, #7d2fc7 56%, #9c30d9 67%, #9c30d9 100%);
  padding-top: 100px;
  padding-bottom: 80px; }
  .sec19 .slider-text {
    margin-bottom: 100px; }
    .sec19 .slider-text .slick-dots {
      bottom: 27px; }
    .sec19 .slider-text .slick-prev {
      top: auto;
      left: calc(50% - 150px);
      bottom: 25px; }
    .sec19 .slider-text .slick-next {
      top: auto;
      right: calc(50% - 150px);
      bottom: 25px; }
  .sec19 .white-block {
    background-color: #ffffff;
    padding: 70px 100px;
    min-height: 390px; }
    .sec19 .white-block .man-info {
      max-width: 210px; }
      .sec19 .white-block .man-info .man-photo {
        width: 166px;
        height: 166px;
        border-radius: 50%;
        margin-bottom: 20px;
        background-position: 50% 0;
        background-size: cover;
        background-repeat: no-repeat; }
        .sec19 .white-block .man-info .man-photo.chapman {
          background-image: url(../img/speakers2018/chapman_new2.jpg); }
        .sec19 .white-block .man-info .man-photo.karpyuk {
          background-image: url(../img/speakers2018/karpyuk_new.jpg); }
        .sec19 .white-block .man-info .man-photo.solovev {
          background-image: url(../img/speakers2018/solovev_new.jpg); }
        .sec19 .white-block .man-info .man-photo.lisenko {
          background-image: url(../img/speakers2018/lisenko.jpg); }
      .sec19 .white-block .man-info .man-name {
        padding-left: 21px;
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; }
      .sec19 .white-block .man-info .man-prof {
        padding-left: 21px;
        color: #000000;
        font-size: 16px;
        line-height: 20px; }
    .sec19 .white-block .citate {
      color: #000000;
      font-size: 35px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.7px;
      max-width: 710px;
      position: relative; }
      .sec19 .white-block .citate:before {
        content: '';
        display: block;
        position: absolute;
        width: 50px;
        height: 40px;
        background: url(../img/citate-icon.png) 0 0 no-repeat;
        left: 0;
        top: -50px; }
  .sec19 .vaucher-title {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.96px;
    margin-right: 275px; }
  .sec19 .open-sale-info {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    max-width: 205px;
    padding-top: 26px;
    padding-left: 23px;
    background: url(../img/osi-decor.png) 0 0 no-repeat;
    margin-bottom: 130px; }

.sec20 {
  padding-top: 100px;
  padding-bottom: 50px; }
  .sec20 .sec-title {
    color: #111111;
    font-size: 68px;
    line-height: 75px;
    font-weight: 700;
    margin-bottom: 60px; }
  .sec20 .map {
    height: 409px;
    position: relative;
    margin-bottom: 90px; }
  .sec20 .place-info-block {
    position: absolute;
    top: 40px;
    right: 50px;
    width: 457px;
    height: 329px;
    background-color: rgba(255, 255, 255, 0.93);
    background-image: url(../img/map-bg.png);
    background-position: 100% 0;
    background-repeat: no-repeat;
    padding-left: 60px;
    padding-top: 45px; }
    .sec20 .place-info-block .plus-wrap {
      position: relative;
      width: 100%; }
      .sec20 .place-info-block .plus-wrap .plus {
        position: absolute;
        width: 31px;
        height: 31px;
        right: 50px;
        top: 20px;
        background: url(../img/plus.svg) 50% no-repeat; }
    .sec20 .place-info-block .place-name {
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 15px; }
      .sec20 .place-info-block .place-name b {
        display: block;
        color: #000000;
        line-height: 30px;
        font-size: 24px;
        font-weight: 800; }
    .sec20 .place-info-block p.place-adress {
      color: #000000;
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      padding-left: 22px;
      background: url(../img/adress-icon.svg) 0 0 no-repeat;
      margin-bottom: 25px; }
    .sec20 .place-info-block .text {
      color: #000000;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px; }
    .sec20 .place-info-block .phone {
      display: block;
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 10px; }
    .sec20 .place-info-block .email {
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-decoration: underline; }
  .sec20 .faq .block-title {
    color: #111111;
    font-size: 48px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: -0.96px; }
  .sec20 .faq .question {
    padding-left: 45px;
    margin-bottom: 50px;
    position: relative;
    transition: all 0.3s linear; }
    .sec20 .faq .question:before {
      content: '';
      display: none;
      position: absolute;
      width: 85px;
      height: 85px;
      background: url(../img/question-icon.png) 50% no-repeat;
      left: -32px;
      top: -23px;
      transition: all 0.3s linear; }
    .sec20 .faq .question.active {
      transition: all 0.3s linear; }
      .sec20 .faq .question.active:before {
        transition: all 0.3s linear;
        display: block; }
      .sec20 .faq .question.active .question-text {
        text-decoration: none; }
  .sec20 .faq .question-text {
    color: #0a0a0a;
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    text-decoration: underline;
    cursor: pointer; }
  .sec20 .faq .answer {
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    display: none; }

.sec21 {
  background-color: #f3f3f3;
  padding-top: 90px;
  padding-bottom: 60px; }
  .sec21 .sec-title {
    color: #111111;
    font-size: 68px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -1.36px;
    margin-bottom: 65px; }
  .sec21 .block-title {
    color: #020202;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 15px; }
  .sec21 .sponsors-block {
    margin-bottom: 100px; }
  .sec21 .sponsors-slider .sponsor {
    display: inline-block;
    float: left;
    vertical-align: top;
    width: 100%;
    max-width: 440px;
    margin: 0 12px;
    height: 242px;
    background-repeat: no-repeat;
    background-position: 50%; }
  .sec21 .sponsors-slider .sponsor1 {
    background-image: url(../img/sponsors/sponsor1.jpg); }
  .sec21 .sponsors-slider .sponsor2 {
    background-image: url(../img/sponsors/sponsor2.png); }
  .sec21 .partners-slider .partner {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    height: 150px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: 50%;
    outline: none; }
  .sec21 .partner1 {
    background-image: url(../img/partners/partner1.png); }
  .sec21 .partner2 {
    background-image: url(../img/partners/partner2.png); }
  .sec21 .partner3 {
    background-image: url(../img/partners/partner3.png); }
  .sec21 .partner4 {
    background-image: url(../img/partners/partner4.png); }
  .sec21 .partner5 {
    background-image: url(../img/partners/partner5.jpg); }
  .sec21 .partner6 {
    background-image: url(../img/partners/partner6.jpg); }
  .sec21 .partner7 {
    background-image: url(../img/partners/partner7.png); }
  .sec21 .partner8 {
    background-image: url(../img/partners/partner8.png); }
  .sec21 .partner9 {
    background-image: url(../img/partners/partner9.png); }
  .sec21 .partner10 {
    background-image: url(../img/partners/partner10.png); }
  .sec21 .partner11 {
    background-image: url(../img/partners/partner11.png); }
  .sec21 .partner12 {
    background-image: url(../img/partners/partner12.png); }

.sec22 {
  background-image: linear-gradient(to right, #1b1044 0%, #1b1044 30%, #6427a9 53%, #9c30d9 69%, #9c30d9 100%);
  padding-top: 120px;
  padding-bottom: 85px;
  position: relative;
  overflow: hidden; }
  .sec22 .wave-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 200px; }
  .sec22 .sec-title {
    color: #ffffff;
    line-height: 56px;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.96px;
    margin-bottom: 50px; }
  .sec22 .info-block {
    max-width: 360px;
    margin: 0 auto; }
    .sec22 .info-block .text1 {
      color: #ffffff;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 70px; }
    .sec22 .info-block .text2 {
      color: #fdfdfd;
      font-size: 23px;
      line-height: 30px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.46px;
      padding-left: 111px;
      background: url(../img/s22-t2-decor.svg) 0 0 no-repeat;
      margin-bottom: 125px; }
      .sec22 .info-block .text2 span {
        text-decoration: underline; }
        .sec22 .info-block .text2 span a {
          color: #fff; }
  .sec22 .btn {
    max-width: 395px;
    padding-left: 30px;
    background-repeat: no-repeat;
    position: relative; }
    .sec22 .btn:before {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 100px);
      top: calc(50% - 15px);
      background-repeat: no-repeat;
      background-position: 50%;
      width: 30px;
      height: 30px; }
  .sec22 .btn-sponsor:before {
    background-image: url(../img/btn-sponsor-icon.svg); }
  .sec22 .btn-partner:before {
    background-image: url(../img/btn-partner-icon.svg); }
  .sec22 .btn-stend:before {
    background-image: url(../img/btn-stend-icon.png); }

.sec23 {
  background: #030303 url(../img/sec23.png) 50% 0 no-repeat;
  padding-top: 100px;
  padding-bottom: 75px; }
  .sec23 .sec-title {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.96px;
    max-width: 400px;
    margin-bottom: 120px; }
  .sec23 .text1 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 320px;
    margin-bottom: 35px; }
  .sec23 .text2 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 115px; }
    .sec23 .text2 span {
      color: #fd1a83; }
  .sec23 .white-block {
    box-shadow: 0 40px 130px rgba(61, 61, 61, 0.5);
    background-color: #ffffff;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 413px;
    background: #fff url(../img/s23-vaucher.png) 0 0 no-repeat; }
    .sec23 .white-block .vaucher-title {
      color: #000000;
      font-size: 35px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.7px;
      margin-bottom: 40px; }
      .sec23 .white-block .vaucher-title span {
        color: #7153ff; }

.footer1,
.footer2,
.footer3 {
  vertical-align: top;
  display: inline-block; }

footer {
  font-family: Roboto;
  font-weight: 500;
  vertical-align: top;
  padding-bottom: 35px;
  width: 100%;
  min-height: 250px;
  background-color: #080808;
  text-align: center; }

footer br.sm {
  display: none; }

footer br.xs {
  display: none; }

footer p {
  color: rgba(255, 255, 255, 0.45);
  font-size: 13px;
  line-height: 20px;
  text-align: left; }

footer .social {
  margin-bottom: 30px;
  text-align: center; }

footer a {
  padding: 0 10px;
  padding-left: 0px;
  color: #fff;
  font-size: 13px;
  transition: all 0.3s linear;
  text-align: left; }

footer a:hover {
  text-decoration: none; }

footer a.partners {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  border: 1px solid #f45c33; }

footer a.partners:hover {
  color: #fff;
  background-color: #f45c33; }

footer .contacts {
  margin-top: 0; }

footer .copy {
  margin-top: 44px;
  margin-bottom: 0; }

footer .icon-soc {
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: top;
  margin: 0px 20px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  color: #000;
  padding: 0px; }
  footer .icon-soc i {
    text-align: center;
    font-size: 20px;
    line-height: 35px;
    margin: 0 auto; }

footer a.icon-soc:hover {
  opacity: 0.8; }

footer .icon-fb {
  margin-left: 0px; }

footer .icon-yt {
  margin-right: 0px; }

footer .modal-btn {
  padding-left: 20px;
  display: block;
  margin-top: 0px;
  margin-bottom: 14px; }

footer .modal-btn:before {
  content: '\f00c';
  font-family: FontAwesome;
  margin-left: -10px;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.41); }

.footer2 {
  padding-top: 60px;
  margin-left: 30px; }

.footer3 {
  padding-top: 58px;
  margin-left: 30px; }

.footer3 p.text4 {
  line-height: 25px;
  text-align: right;
  font-family: Roboto;
  font-weight: 400; }

.footer3 p.text4 span a {
  font-size: 16px;
  padding-right: 70px;
  float: left;
  font-family: Roboto;
  font-weight: 700; }

.footer-line {
  display: inline-block;
  margin-top: 35px;
  margin-bottom: 35px;
  width: 90%;
  height: 1px;
  background-color: #6c6c6c;
  opacity: 0.3; }

footer .bottom-text {
  font-size: 13px;
  line-height: 22px;
  text-align: center; }

footer a.mail {
  background-color: #f8ff44;
  color: #636475;
  font-weight: 700; }

@media only screen and (max-width: 1130px) {
  footer .footer3 {
    margin-left: 0px; } }

@media only screen and (max-width: 720px) {
  footer .copy {
    margin: 24px auto;
    display: block; }
  .footer2 {
    padding-top: 0px;
    margin: 0 auto;
    display: block; }
  footer p {
    color: #979898;
    font-size: 13px;
    text-align: center; }
  footer a.icon-soc {
    display: inline-block;
    width: 35px;
    height: 35px;
    vertical-align: top;
    margin: 0px 10px; }
  .footer3 p.text4 span {
    color: #636475;
    font-size: 16px;
    padding-right: 20px; }
  .footer-inline {
    padding-left: 50px;
    padding-top: 20px; }
  .footer3 {
    padding-top: 38px;
    margin-left: 0px; }
  footer .bottom-text br {
    display: none; } }
