

.vaucher-btn {
	border-radius: 2px;
	background-image: linear-gradient(-235deg, #fc134c 0%, #fe1fa0 65%, #fc25d8 100%);
	width: 158px;
	line-height: 48px;
	text-align: center;
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}

.btn {
	max-width: 429px;
	width: 100%;
	border-radius: 2px;
	background-image: linear-gradient(-200deg, #fc134c 0%, #fe1fa0 65%, #fc25d8 100%);
	color: #ffffff;
	font-size: 17px;
	font-weight: 500;
	line-height: 75px;
	letter-spacing: 0.17px;
	text-align: center;

}

.getvaucher {
	padding-right: 50px;
	span {
		width: 50px;
		height: 40px;
		position: absolute;
		background: url(../img/tickets.svg) 50% no-repeat;
		top: 50%;
		transform: translateY(-50%);
		left: calc(100% - 80px);
	}
}

.subm {
	cursor: pointer;
	border: none;
	display: block;
	margin: 0 auto;
	// transition: all 0.3s linear;
	max-width: 100%;
	line-height: 83px;
	// &:hover,
	// &:focus {
	// 	transform: scale(1.02);
	// }
	text-transform: uppercase;
	span {
		left: calc(50% + 170px);
	}
}
