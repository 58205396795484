.header {
	background-color: #000;
	padding: 13px 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 20;
	.topline {

	}
	.header-menu {
		li {
			display: inline-block;
			margin-right: 20px;
			&:last-child {margin-right: 0;}
		}
		.menu-link {
			color: #ffffff;
			font-size: 14px;
			font-weight: 500;
			transition: all 0.3s linear;
			&:hover{
				color: #f3045d;
			}
		}
	}
	.phone {
		color: #ffffff;
		font-size: 15px;
		font-weight: 700;
	}
	.mobile-menu-icon {
		display: none;
		color:rgba(#fff, 0.57);
		font-size: 14px;
		font-weight: 400;
		background: url(../img/mob-menu-icon.svg) 50% 0 no-repeat;
		width: 40px;
		text-align: center;
		padding-top: 20px;
	}
	.timer-wrap {
		background: #000;
		height: 65px;
		width: 100%;
		max-width: 685px;
		transform: translate(0,-65px);
		transition: 0.4s linear;
		position: absolute;
		top: 0px;
		left: calc(50% - 440px);
		z-index: 99;
		text-align: center;
		&.active {
			transform: translate(0,0);
		}
	}
	.timer-title {
		padding-top: 5px;
		display: inline-block;
		max-width: 330px;
		text-align: left;
		color: #fff;
		font-size: 23px;
		line-height: 28px;
		vertical-align: top;
		text-transform: uppercase;

	}
}